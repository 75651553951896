import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import {
  PlayFill,
  PauseFill,
  ThumbsUpFill,
  ArrowUpRightFromSquare,
  ArrowShapeDownToLine,
} from "@gravity-ui/icons";
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast";
import AudioProcessor from "./AudioProcessor";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { usePostHog } from "posthog-js/react";
import { getCookie } from "../../utils/cookieUtils";

const ProjectLikedTracksList = ({ tracks }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    setDiscoverId,
  } = useAudioPlayer();
  const { updateLocalState } = useLocalState();

  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState("error");
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(null);

  const handleDownload = (track) => {
    setSelectedTrack(track);
    setIsDownloadModalOpen(true);
    posthog.capture("Project Liked Tracks - Click on Download");
  };

  const handlePlayPause = (track) => {
    if (isPlaying && currentAudioSrc === track.audioSrc) {
      pauseAudio();
    } else if (currentAudioSrc === track.audioSrc) {
      resumeAudio();
    } else {
      setDiscoverId(null);
      playAudio(
        track.audioSrc,
        track.name,
        track.description,
        track.imageSrc,
        track.duration
      );
    }
  };

  const formatTime = (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const navigateToProject = (projectId, responseId) => {
    posthog.capture("Project Liked Tracks - Click on Go to Project");
    updateLocalState("scrollToAiResponse", responseId);
    navigate(`/chatPage?projectId=${projectId}`);
  };

  const handleUnlike = async (e, track) => {
    e.stopPropagation();
    const userId = getCookie("user_id");

    if (!userId || !track.response_id) return;

    const payload = {
      response_id: track.response_id,
      user_id: userId,
      is_like: true, // We send true because we're toggling an existing like
    };

    try {
      posthog.capture("Project Liked Tracks - Unlike Track");

      // Remove the like from the backend
      await apiService.sendRequest("removeAIResponseLike", payload);

      // Update local state to remove the track from liked tracks
      const currentLikedTracks = await apiService.handleProtectedRoute(
        "fetchLikedProjectTracks",
        {}
      );

      const processedData = currentLikedTracks.map((track) => ({
        ...track,
        audioSrc:
          track.is_streaming && track.request_id
            ? `${process.env.REACT_APP_SERVER_ENDPOINT}/api/audio-stream?requestId=${track.request_id}`
            : `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${track.response_id}`,
        imageSrc: track.signedUrlExists
          ? track.cover_image_name
          : !track.signedUrlExists && track.cover_image_name
          ? `/project-images/${track.cover_image_name}`
          : "/project-images/Album_Art_6.webp",
      }));

      updateLocalState("likedProjectTracks", processedData);

      // Show success toast
      setToastMessage("Track removed from liked tracks");
      setToastType("success");
      setTimeout(() => setToastMessage(null), 3000);
    } catch (error) {
      console.error("Error unliking track:", error);
      setToastMessage("Failed to unlike track");
      setToastType("error");
      setTimeout(() => setToastMessage(null), 3000);
    }
  };

  return (
    <div className="w-full">
      {tracks.map((track) => (
        <div
          key={track.response_id}
          onClick={() => handlePlayPause(track)}
          style={
            isPlaying && currentAudioSrc === track.audioSrc
              ? {
                  background:
                    "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
                }
              : {}
          }
          className={`flex items-center p-1.5 pr-4 m-1.5 rounded-xl cursor-pointer group relative ${
            isPlaying && currentAudioSrc === track.audioSrc
              ? "bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 text-white"
              : "hover:bg-opacity-10 transition duration-500 hover:bg-white"
          }`}
        >
          <div className="flex items-center relative overflow-hidden mr-4">
            <img
              src={track.imageSrc}
              alt={track.name}
              className="w-12 h-12 object-cover rounded-lg"
            />
            <button
              id="project-play-pause-button"
              onClick={(e) => {
                handlePlayPause(e);
              }}
              className={`absolute inset-0 flex items-center justify-center text-white transition-transform duration-500 transform group-hover:opacity-100 ${
                isPlaying && currentAudioSrc === track.audioSrc
                  ? "md:translate-y-0"
                  : "md:translate-y-[70%] md:group-hover:translate-y-0"
              }`}
            >
              {isPlaying && currentAudioSrc === track.audioSrc ? (
                <PauseFill className="w-4 h-4" />
              ) : (
                <PlayFill className="w-4 h-4" />
              )}
            </button>
          </div>

          <div className="flex-1 min-w-0">
            <p className="font-medium text-base truncate">{track.name}</p>
            <p className="font-light text-sm text-gray-400 md:w-full truncate w-2/3">
              {track.description}
            </p>
          </div>

          <div className="flex flex-1 justify-between">
            <div className="hidden md:flex flex-1 min-w-0">
              <p className="font-light text-sm text-gray-400">
                {track.projectname}
              </p>
            </div>

            <div className="flex flex-1">
              <span className="font-light text-sm text-white">
                {typeof track.duration === "string"
                  ? track.duration
                  : formatTime(track.duration) || "0:00"}
              </span>
            </div>

            <div className="flex items-center gap-6">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigateToProject(track.project_id, track.response_id);
                }}
                className="text-[#878188] hover:text-white"
              >
                <ArrowUpRightFromSquare className="w-4 h-4" />
              </button>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownload(track);
                }}
                className="text-[#878188] hover:text-white"
              >
                <ArrowShapeDownToLine className="w-4 h-4" />
              </button>
              <button
                onClick={(e) => handleUnlike(e, track)}
                className="text-[#878188] hover:text-white"
              >
                <ThumbsUpFill className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      ))}
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
      {isDownloadModalOpen && selectedTrack && (
        <AudioProcessor
          currentResponse={{
            ai_response_id: selectedTrack.response_id,
            audio: {
              name: selectedTrack.name,
              duration:
                typeof selectedTrack.duration === "string"
                  ? selectedTrack.duration
                  : formatTime(selectedTrack.duration) || "0:00",
            },
            avatar: selectedTrack.imageSrc,
            musicDescription: selectedTrack.description,
          }}
          onClose={() => setIsDownloadModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ProjectLikedTracksList;
