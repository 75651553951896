import { useNavigate } from "react-router-dom";
import Button from "../components/generic_components/Button";
import CustomInput from "../components/custom_components/CustomInput";
import InputWithButton from "../components/generic_components/InputWithButton";
import EditableField from "../components/custom_components/EditableField";
import ProjectCard from "../components/generic_components/ProjectCard";
import ProjectGrid from "../components/generic_components/ProjectGrid";
import AudioPlayer from "../components/custom_components/AudioPlayer";
import Dropdown from "../components/generic_components/Dropdown";
import DiscoverPage from "../components/custom_components/DiscoverPage";
import Header from "../components/custom_components/Header";
import ChatInterface from "../components/custom_components/ChatInterface";
import DetailsPanel from "../components/custom_components/DetailsPanel";
import ProjectPage from "../components/custom_components/ProjectPage";
import ChatBoxLoader from "../components/custom_components/ChatBoxLoader";
import Image from "../components/generic_components/Image";
import JoinOrganization from "../components/custom_components/JoinOrganization";
import TeamMembers from "../components/custom_components/TeamMembers";
import TeamDetails from "../components/custom_components/TeamDetails";
import Settings from "../components/custom_components/Settings";
import UserNameAndAvatar from "../components/custom_components/UserNameAndAvatar";
import TeamNameAndAvatar from "../components/custom_components/TeamNameAndAvatar";
import CreditsCard from "../components/custom_components/CreditsCard";
import Notifications from "../components/custom_components/ResponsiveNotification";
import WubbleNotification from "../components/custom_components/WubbleNotification";
import NotificationButton from "../components/custom_components/NotificationButton";
import Subscription from "./../components/custom_components/Subscription/Subscription";
import LikedTracks from "../components/custom_components/LikedTracks";
import FeedbackForm from "../components/custom_components/FeedbackForm";
import MobileMenu from "../components/custom_components/MobileMenu";
import DiscoverIntro from "../components/custom_components/DiscoverIntro";
import ActivePlan from "../components/custom_components/ActivePlan";
import SidebarProjects from "../components/custom_components/SidebarProjects";

export const componentMapper = {
  SidebarProjects: SidebarProjects,
  Header: Header,
  ChatInterface: ChatInterface,
  DiscoverPage: DiscoverPage,
  DetailsPanel: DetailsPanel,
  joinOrganization: JoinOrganization,
  teamMembers: TeamMembers,
  teamDetails: TeamDetails,
  ProjectPage: ProjectPage,
  settings: Settings,
  Dropdown: Dropdown,
  button: Button,
  customInput: CustomInput,
  InputWithButton: InputWithButton,
  editableField: EditableField,
  ProjectGrid: ProjectGrid,
  ProjectCard: ProjectCard,
  AudioPlayer: AudioPlayer,
  ChatBoxLoader: ChatBoxLoader,
  UserNameAndAvatar: UserNameAndAvatar,
  TeamNameAndAvatar: TeamNameAndAvatar,
  WubbleNotification: WubbleNotification,
  CreditsCard: CreditsCard,
  Notifications: Notifications,
  NotificationButton: NotificationButton,
  Subscription: Subscription,
  LikedTracks: LikedTracks,
  FeedbackForm: FeedbackForm,
  MobileMenu: MobileMenu,
  DiscoverIntro: DiscoverIntro,
  ActivePlan: ActivePlan,
  h1: (props) => <h1 {...props}>{props.text}</h1>,
  h2: (props) => <h2 {...props}>{props.text}</h2>,
  h3: (props) => <h3 {...props}>{props.text}</h3>,
  p: (props) => <p {...props}>{props.text}</p>,
  div: (props) => {
    const navigate = useNavigate();

    const handleClick = () => {
      if (props.navigate) {
        navigate(props.navigate);
      }
    };

    return (
      <div {...props} onClick={handleClick}>
        {props.children}
      </div>
    );
  },
  video: (props) => (
    <video autoPlay loop muted playsInline {...props}>
      <source src={props.src} type={props.type || "video/mp4"} />
      Your browser does not support the video tag.
    </video>
  ),
  span: (props) => <span {...props}>{props.text}</span>,
  image: Image,
  select: (props) => <select {...props}></select>,
  svg: (props) => <svg {...props}></svg>,
  b: (props) => <b {...props}></b>,
};
