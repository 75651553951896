import {
  File,
  Xmark,
  StopFill,
  ArrowLeft,
  ArrowRotateRight,
} from "@gravity-ui/icons";
import { usePostHog } from "posthog-js/react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useAuth } from "../../contexts/AuthContext";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { getCookie } from "../../utils/cookieUtils";
import Dropdown from "../generic_components/Dropdown";
import Toast from "../generic_components/Toast";
import Modal from "../modal/Modal";
import ModalContent from "../modal/ModalContent";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubscriptionModal,
  setSubscriptionTypeModal,
  setRedirectAfterSelectedPlan,
} from "../../store/reducers/subscriptionSlice";
import { randomPrompts } from "../../utils/randomPrompts";

const truncateFileName = (fileName, maxLength) => {
  const extension = fileName.split(".").pop();
  const baseName = fileName.substring(
    0,
    fileName.length - extension.length - 1
  );

  if (baseName.length + extension.length + 1 <= maxLength) {
    return fileName;
  }

  const truncatedBaseName = baseName.substring(
    0,
    maxLength - extension.length - 1
  );
  return `${truncatedBaseName}...${extension}`;
};

const CustomInput = ({
  className,
  dropdownStyle,
  closeModal,
  audioFile,
  requestData,
  project_id,
  chatPage,
  imageSrc,
  mood,
  onRedo,
  fromDiscoverPage,
  id,
}) => {
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const { localState, updateLocalState } = useLocalState();
  const [modalContent, setModalContent] = useState(null);
  const [file, setFile] = useState(null);
  const [textPrompt, setTextPrompt] = useState(localState.textPrompt || "");
  const [filePreview, setFilePreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const navigate = useNavigate();
  const textareaRef = useRef(null);
  const maxHeight = 90;
  const [inputDisplay, setInputDisplay] = useState(chatPage);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const [inChatPage, setInChatPage] = useState(chatPage);
  const { isLoggedIn } = useAuth();
  const [diceIsClicked, setDiceIsClicked] = useState(false);
  const [lastPromptIndex, setLastPromptIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [shouldGenerate, setShouldGenerate] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(false);
  const dropRef = useRef(null);
  const [charCount, setCharCount] = useState(0);
  const [userData, setUserData] = useState();
  const MAX_CHARS = 2000;
  const [showSuggestion, setShowSuggestion] = useState(true);

  const [userCreditsRemaining, setUserCreditsRemaining] = useState(
    localState?.userProfileData?.remaining_credit
  );

  useEffect(
    () =>
      setUserCreditsRemaining(localState?.userProfileData?.remaining_credit),
    [localState?.userProfileData?.remaining_credit]
  );

  const [isDragging, setIsDragging] = useState(false);

  const showToast =
    toastMessage === "File size exceeds the 20 MB limit." ||
    toastMessage === "Please enter some text." ||
    toastMessage === "Please wait for the current response to finish." ||
    toastMessage === "Maximum character limit reached.";

  const handleDiceClick = () => {
    setDiceIsClicked(true);

    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    posthog.capture("Click on Randomize Prompt Icon Button");

    // Randomly select an index different from the last one
    let randomIndex;
    do {
      randomIndex = Math.floor(Math.random() * randomPrompts.length);
    } while (randomIndex === lastPromptIndex); // Ensure new index is different from the last

    // Update state with the random prompt
    const randomPrompt = randomPrompts[randomIndex];
    setTextPrompt(randomPrompt);
    setCharCount(randomPrompt.length);
    setLastPromptIndex(randomIndex); // Update last prompt index state

    // Reset the clicked state after animation (0.3s for example)
    setTimeout(() => {
      setDiceIsClicked(false);
    }, 300); // Adjust duration as needed
  };

  const location = useLocation();

  useEffect(() => {
    updateLocalState("textPrompt", "");
    updateLocalState("file", null);

    // Reset textPrompt when location changes
    setTextPrompt("");
    if (location.pathname !== "/chatPage")
      updateLocalState("nextPromptSuggestion", null);
    setFile(null);
  }, [location.pathname]);

  useEffect(() => {
    setInputDisplay(false);
  }, [file]);

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     textareaRef.current.style.height = "auto";
  //     textareaRef.current.style.height = `${Math.min(
  //       textareaRef.current.scrollHeight,
  //       maxHeight
  //     )}px`;
  //     textareaRef.current.style.overflow =
  //       textareaRef.current.scrollHeight > maxHeight ? "auto" : "hidden";

  //     // Check if the text area is empty or contains only one non-blank line
  //     const lines = textPrompt
  //       ?.split("\n")
  //       .filter((line) => line.trim() !== "");
  //     const isOneLine =
  //       lines?.length <= 1 && textareaRef.current.scrollHeight <= 24;

  //     if (isOneLine && chatPage && !file) {
  //       setInputDisplay(true);
  //     } else if (!chatPage) {
  //       setInputDisplay(false);
  //     }
  //   }
  // }, [textPrompt, chatPage, file]);

  useEffect(() => {}, [textPrompt, chatPage, file]);

  // Fetch user data once when the provider mounts
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await apiService.handleProtectedRoute(
  //         "fetchUserProfile",
  //         {}
  //       );

  //       updateLocalState("userProfileData", response);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   };

  //   if (isLoggedIn) {
  //     fetchUserData();
  //   }
  // }, []);

  // Add the circular progress component
  const CircularProgress = ({ value, maxChars }) => {
    const radius = 8; // Radius of the circle
    const circumference = 2 * Math.PI * radius;

    // Calculate progress percentage and cap it at 100%
    const progress = Math.min((value / maxChars) * 100, 100);

    // Compute stroke offset based on capped progress
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
      <div className="relative flex items-center justify-center">
        <span className="text-xs font-extralight text-[#C5C3C6]">
          {Math.min(value, maxChars)}
        </span>
        <svg className="w-8 h-8 transform -rotate-90">
          <circle
            className="text-gray-700"
            strokeWidth="2"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="16"
            cy="13"
          />
          <circle
            className={`${value >= maxChars ? "text-red-500" : "text-primary"}`}
            strokeWidth="2"
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="16"
            cy="13"
          />
        </svg>
      </div>
    );
  };

  const handleChange = (e) => {
    let inputValue = e.target.value; // Create a copy of the input value
    setCharCount(inputValue.length);
    const wordCount = inputValue.trim().split(/\s+/).length;

    // Character limit
    const charLimit = 2000;

    // Enforce character limit and truncate pasted input
    if (
      (inputValue.length > charLimit && userData?.remaining_credits !== null) ||
      userData?.remaining_credits === 0
    ) {
      setDisableGenerate(true); // Disable the generate button
      if (inputValue.length > charLimit) {
        setToastMessage("Maximum character limit exceeded."); // Display toast message
        setToastType("error"); // Set toast type to error
      }
    } else {
      setDisableGenerate(false); // Enable the generate button
    }

    setTextPrompt(inputValue);

    // Capture the event for typing in the music creation prompt
    if (inputValue?.trim()) {
      posthog.capture("Typing in Music Creation Prompt");
    }
    // Calculate the number of lines
    const lines = inputValue.split("\n").filter((line) => line.trim() !== "");

    // Adjust the textarea height dynamically
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        100
      )}px`;
      textareaRef.current.style.overflow =
        textareaRef.current.scrollHeight > 100 ? "auto" : "hidden";
    }

    // Check for input display logic based on conditions
    if (
      lines.length <= 1 &&
      textareaRef.current.scrollHeight <= 24 &&
      inputValue.length <= 90 && // Character limit applied here
      chatPage &&
      !file
    ) {
      setInputDisplay(true);
    } else if (lines.length > 1 || inputValue.length > 71) {
      setInputDisplay(false);
    }
  };

  useEffect(() => {
    // Focus the textarea when the component mounts
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []); //

  useEffect(() => {
    // Calculate the number of lines
    const lines = textPrompt?.split("\n").filter((line) => line.trim() !== "");

    // Adjust the textarea height dynamically
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        100
      )}px`;
      textareaRef.current.style.overflow =
        textareaRef.current.scrollHeight > 80 ? "auto" : "hidden";
    }

    chatPage &&
      textareaRef.current.scrollHeight <= 98 &&
      updateLocalState("textInputHeight", textareaRef.current.scrollHeight);

    if (
      lines?.length <= 1 &&
      textareaRef.current.scrollHeight <= 24 &&
      textPrompt.length <= 90 &&
      chatPage &&
      !file
    ) {
      // chatPage && updateLocalState("textInputHeight", null);
      setInputDisplay(true);
    } else if (
      lines?.length > 1 ||
      textPrompt.length > 71 ||
      textareaRef.current.scrollHeight > 24
    ) {
      setInputDisplay(false);
    }
  }, [textPrompt, chatPage, file]);

  useEffect(() => {
    if (file && file instanceof Blob) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
        setIsUploading(false);
      };
      setIsUploading(true);
      reader.readAsDataURL(file.file);
    } else {
      setFilePreview(null);
    }
  }, [file]);

  useEffect(() => {
    if (audioFile) {
      const fileData = {
        name: audioFile.name,
        type: "audio/wav",
        url: audioFile.url,
      };
      setFile(fileData);
    }
  }, [audioFile]);

  const handleOptionSelect = (option) => {
    posthog.capture(`Click on Attachment type ${option?.label}`);

    setModalContent(option);
  };

  const MAX_FILE_SIZE_MB = 20; // Maximum file size in MB
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert to bytes

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
        posthog.capture("File size exceeds the 20 MB limit. Upload failed");
        // File size exceeds the limit
        setToastMessage("File size exceeds the 20 MB limit.");
        setToastType("error");
        setIsUploading(false);
        return;
      }

      setModalContent(null);

      setIsUploading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("folder", "user-uploads");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/upload`,
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        setFile({
          name: selectedFile.name,
          type: selectedFile.type,
          file: selectedFile,
          url: data.url,
        });

        posthog.capture("File uploaded successfully");
      } catch (error) {
        posthog.capture("Error uploading file");
        console.error("Error uploading file:", error);
        setToastMessage("Failed to upload file");
        setToastType("error");
      }

      // fromDiscoverPage
      //   ? setFile(selectedFile)
      //   : setFile({
      //       name: selectedFile.name,
      //       type: selectedFile.type,
      //       file: selectedFile,
      //     });
      setIsUploading(false);
    }
    setModalContent(null);
  };

  const handleFileRemove = () => {
    setFile(null);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];

    // Check file type
    const isAudio = droppedFile.type.startsWith("audio/");
    const isImage = droppedFile.type.startsWith("image/");
    const isVideo = droppedFile.type.startsWith("video/");
    const isText = droppedFile.type === "text/plain";
    const isPDF = droppedFile.type === "application/pdf";

    if (isAudio || isImage || isVideo || isText || isPDF) {
      // Create file object matching the structure expected by handleFileChange
      const fileEvent = {
        target: {
          files: [droppedFile],
        },
      };
      handleFileChange(fileEvent);
    } else {
      setToastMessage(
        "Invalid file type. Only audio, image, and video, text, and PDF files are accepted."
      );
      setToastType("error");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    posthog.capture("Dragging file over the drop zone");
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Check if the drag event is leaving the entire drop zone
    if (!dropRef.current.contains(e.relatedTarget)) {
      setIsDragging(false);
    }
  };

  // Update textPrompt when localState.textPrompt changes
  useEffect(() => {
    if (localState.textPrompt !== undefined) {
      setTextPrompt(localState.textPrompt);
    }
  }, [localState.textPrompt]);

  // Add effect to reset showSuggestion when new suggestion comes in
  useEffect(() => {
    if (localState.nextPromptSuggestion) {
      setShowSuggestion(true);
    }
  }, [localState.nextPromptSuggestion]);

  // Update file when localState.file changes
  // useEffect(() => {
  //   if (!localState.file) {
  //     setFile(localState.file);
  //   }
  // }, [localState.file]);

  useEffect(() => {
    if (localState.suggestionClicked) {
      if (localState?.userProfileData?.remaining_credits <= 0) {
        if (localState.isAdmin && localState.isTeamMember) {
          dispatch(setRedirectAfterSelectedPlan("/create-team"));
          dispatch(setSubscriptionModal(true));
          dispatch(setSubscriptionTypeModal("upgrade"));
        } else if (!localState.isTeamMember) {
          dispatch(setRedirectAfterSelectedPlan("/create-team"));
          dispatch(setSubscriptionModal(true));
        }
        return;
      }

      const { text, file } = localState.suggestionClicked;
      setTextPrompt(text);

      const processFile = async () => {
        let newFile = null;
        setIsUploading(true);
        if (file) {
          newFile = {
            name: file.name,
            type: file.type,
            file: file.file,
            url: file.url,
          };
          setFile(newFile);
          await handleGenerate(newFile);
        }
        setFile(newFile);
        setIsUploading(false);
      };

      file ? processFile() : text && handleGenerate();
      updateLocalState("suggestionClicked", null);
    }
  }, [localState.suggestionClicked]);

  const handleStopGeneration = async () => {
    updateLocalState("stopGeneration", true);
    posthog.capture("Click Stop generation icon button");
  };

  const handleGenerate = async (suggestionFile) => {
    const currentFile =
      suggestionFile && suggestionFile.file ? suggestionFile : file;

    // Capture the event for clicking the Generate button
    posthog.capture("Click on Generate Icon Button");

    const formData = new FormData();

    const prompt = localState.suggestionClicked
      ? localState.suggestionClicked?.text?.trim()
      : textPrompt?.trim();

    if (localState?.userProfileData?.remaining_credits <= 0) {
      if (localState.isAdmin && localState.isTeamMember) {
        dispatch(setRedirectAfterSelectedPlan("/create-team"));
        dispatch(setSubscriptionModal(true));
        dispatch(setSubscriptionTypeModal("upgrade"));
      } else if (!localState.isTeamMember) {
        dispatch(setRedirectAfterSelectedPlan("/create-team"));
        dispatch(setSubscriptionModal(true));
      }
      return;
    }

    if (localState.responseInProgress) {
      setToastMessage("Please wait for the current response to finish.");
      setToastType("warning");
      return;
    }

    if (!isLoggedIn) {
      updateLocalState("textPrompt", prompt);
      updateLocalState("file", currentFile);
      updateLocalState("showLoginModal", (prev) => !prev);
      return;
    }

    if (!prompt?.trim() && currentFile && fromDiscoverPage) {
      setToastMessage("Please enter some text.");
      setToastType("error");
      return; // Exit the function
    }

    if (prompt?.trim() || currentFile) {
      const userId = getCookie("user_id");

      try {
        let createProject = requestData?.createProject;
        let projectId;

        if (createProject) {
          // Case where a new project needs to be created
          const projectPayload = {
            user_id: userId,
            project_name: "New Project",
            project_description: prompt || "Project Description",
          };

          const createProjectResponse = await apiService.sendRequest(
            "createProject",
            projectPayload
          );
          if (createProjectResponse.error) {
            throw new Error(createProjectResponse.error);
          }

          projectId = createProjectResponse.project.project_id;

          setToastMessage("Project created successfully!");
          setToastType("success");
        } else {
          // Case where the project already exists and is passed as a prop
          projectId = project_id;
        }

        formData.append("project_id", projectId);
        formData.append("user_id", userId);
        formData.append("content", prompt);
        formData.append("sentAt", new Date().toISOString());

        let key = null;
        let fileContent = null;

        if (currentFile) {
          // formData.append("media", currentFile.file); // File object
          formData.append("file_name", currentFile.name);
          formData.append("mime_type", currentFile.type);

          const mediaFileType = currentFile.type.split("/")[0]; // 'audio', 'image', or 'video'
          const urlsObject = {
            audios: mediaFileType === "audio" ? [currentFile.url] : [],
            images: mediaFileType === "image" ? [currentFile.url] : [],
            videos: mediaFileType === "video" ? [currentFile.url] : [],
            documents:
              mediaFileType === "text" || currentFile.type === "application/pdf"
                ? [currentFile.url]
                : [],
          };

          formData.append("mediaUrls", JSON.stringify(urlsObject));
          formData.append("media_url", currentFile.url);

          // const fileType = modalMapping[modalContent]?.messageType || "text";
          // switch (fileType) {
          //   case "image":
          //     key = "image_bytes";
          //     break;
          //   case "audio":
          //     key = "audio_bytes";
          //     break;
          //   case "video":
          //     key = "video_bytes";
          //     break;
          //   default:
          //     key = "file_bytes";
          // }

          // const reader = new FileReader();
          // reader.readAsDataURL(currentFile.file); // Convert to Base64
          // reader.onloadend = async () => {
          //   fileContent = reader.result.split(",")[1]; // Extract the Base64 part

          //   // Append the file content to the formData
          //   formData.append(key, fileContent);
          //   // console.log(fileContent);
          // };
        }
        formData.append("message_type", key || "text");

        const result = await apiService.storeUserMessage(formData);
        if (result.success) {
          setToastMessage(result.success);
          setToastType("success");

          let image_bytes = null;
          let audio_bytes = null;
          let video_bytes = null;

          if (key === "image_bytes") {
            image_bytes = fileContent;
          } else if (key === "audio_bytes") {
            audio_bytes = fileContent;
          } else if (key === "video_bytes") {
            video_bytes = fileContent;
          }

          const userInput = {
            image_bytes,
            audio_bytes,
            video_bytes,
          };

          formData.append("message_id", result.message_id);
          formData.append("userInput", userInput);
          formData.append("sentAt", new Date().toISOString());
          formData.append("prompt", prompt);

          // const newMessage = {
          //   id: result.message_id,
          //   sender: "User",
          //   prompt: textPrompt,
          //   text: textPrompt,
          //   image_bytes: fileContent,
          //   audio_bytes: null,
          //   video_bytes: null,
          //   avatar: "https://via.placeholder.com/50",
          //   file: {
          //     name: file.name,
          //     type: file.type,
          //   },
          //   sentAt: new Date().toISOString(),
          // };

          // console.log(newMessage);

          updateLocalState("newMessage", formData);
          // console.log(formData);

          if (createProject) {
            navigate(`/chatPage?projectId=${projectId}`);
            posthog.capture("New Project created successfully");
          }
        } else {
          posthog.capture("Failed to create New Project");
          setToastMessage(result.error || "An error occurred");
          setToastType("error");
        }

        // else {
        //   const formData = new FormData();
        //   formData.append("project_id", projectId);
        //   formData.append("user_id", userId);
        //   formData.append("content", textPrompt);
        //   formData.append("message_type", "text");

        //   const result = await apiService.storeUserMessage(formData);
        //   if (result.success) {
        //     setToastMessage(result.success);
        //     setToastType("success");

        //     const newMessage = {
        //       id: result.message_id,
        //       sender: "User",
        //       prompt: textPrompt,
        //       text: textPrompt,
        //       image_bytes: null,
        //       audio_bytes: null,
        //       video_bytes: null,
        //       avatar: "https://via.placeholder.com/50",
        //       file: null,
        //       sentAt: new Date().toISOString(),
        //     };

        //     updateLocalState("newMessage", newMessage);

        //     if (createProject) {
        //       navigate(`/chatPage?projectId=${projectId}`);
        //     }
        //   } else {
        //     setToastMessage(result.error || "An error occurred");
        //     setToastType("error");
        //   }
        // }
      } catch (error) {
        console.error("Error storing user message:", error);
        setToastMessage("An error occurred while saving");
        setToastType("error");
      } finally {
        if (closeModal) {
          closeModal();
        }
        updateLocalState("textPrompt", "");
        updateLocalState("file", null);

        setTextPrompt("");
        setFile(null);
        setCharCount(0);
      }
    }
  };

  const modalMapping = {
    Audio: {
      title: "Attach Audio",
      fileTypes: [".mp3", ".aac", ".m4a", ".wav"],
      messageType: "audio",
    },
    Image: {
      title: "Attach Image",
      fileTypes: [".jpg", ".png"],
      messageType: "image",
    },
    Video: {
      title: "Attach Video",
      fileTypes: [".mp4", ".avi", ".mov"],
      messageType: "video",
    },
    Text: {
      title: "Attach Text",
      fileTypes: [".txt", ".pdf"],
      messageType: "text",
    },
  };

  const getModalKey = (option) => {
    // Strip "Upload " prefix if present
    return option.replace("Upload ", "");
  };

  useEffect(() => {
    if (textareaRef.current && chatPage) {
      // Reset height to auto first to handle text removal
      textareaRef.current.style.height = "auto";

      // Calculate required height including the suggestion if shown
      const suggestedHeight =
        showSuggestion && localState.nextPromptSuggestion && !textPrompt
          ? Math.min(
              getTextAreaHeight(localState.nextPromptSuggestion),
              maxHeight
            )
          : Math.min(textareaRef.current.scrollHeight, maxHeight);

      // Set the new height
      textareaRef.current.style.height = `${suggestedHeight}px`;

      updateLocalState("textInputHeight", textareaRef.current.scrollHeight);

      // Set overflow if content exceeds maxHeight
      textareaRef.current.style.overflow =
        suggestedHeight >= maxHeight ? "auto" : "hidden";

      // Update input display based on content height
      setInputDisplay(suggestedHeight <= 24);
    }
  }, [textPrompt, showSuggestion, localState.nextPromptSuggestion]);

  // Helper function to calculate height for a given text
  const getTextAreaHeight = (text) => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.value = text;
    const height = textarea.scrollHeight;
    textarea.value = textPrompt; // Reset to actual value
    return height;
  };

  return (
    <div
      id={id}
      ref={dropRef}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      className={`self-center flex flex-col items-center justify-center gap-0 ${className} ${
        inputDisplay
          ? `rounded-full p-2`
          : inChatPage
          ? "rounded-3xl p-2"
          : "rounded-2xl"
      }${onRedo ? " bg-[#2C2A2C] p-2 rounded-3xl" : ""}`}
    >
      {onRedo && (
        <div className="flex w-full justify-between items-center pb-2 pl-2 pr-1 rounded-xl">
          <p className="text-sm text-start font-extralight ">
            Here is the result based on what you have selected
          </p>
          <button
            onClick={onRedo}
            className="flex items-center gap-1 ml-2 h-7 px-2 text-sm rounded-full bg-white/5 hover:bg-white/10 text-[#878188] hover:text-white transition-colors"
          >
            <ArrowRotateRight className="w-3.5 h-3.5" />
            Redo
          </button>
        </div>
      )}
      {isDragging && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/30 backdrop-blur-sm" />
          <div className="h-[90%] w-[95%] border border-[color:var(--Color-Light-Opacity-White-30,rgba(255,255,255,0.30))] rounded-[20px] border-dashed p-8 flex flex-col items-center justify-center gap-2 z-10">
            <div className="flex flex-col items-center justify-center">
              <img src="drag-drop.svg" alt="Drag and Drop" />
            </div>
            <span className="text-2xl font-medium text-white">Drop it!</span>
            <span className="text-sm text-[#878188] text-center">
              Supported files: Images (.png, .jpg), Audio (.mp3, .wav), Video
              (.mp4, .avi), Documents (.txt, .pdf)
            </span>
            <span className="text-sm text-[#878188]">
              Limit: 1 file, up to 20 MB
            </span>
          </div>
        </div>
      )}
      <div
        className={`self-stretch flex flex-col items-start justify-center bg-[#1d1b1d] border border-solid border-[#3c393c] w-full relative ${
          inputDisplay ? "rounded-full p-1 px-2" : "rounded-2xl p-2"
        }`}
      >
        <div
          className={`self-stretch rounded-xl flex flex-row my-1 items-center justify-${
            inputDisplay ? "between" : "start"
          }`}
        >
          {inputDisplay && (
            <div className="flex items-center justify-center">
              <Dropdown
                options={[
                  {
                    label: "Upload Audio",
                    icon: "MusicNote",
                    id: "audio-option",
                  },
                  {
                    label: "Upload Image",
                    icon: "Picture",
                    id: "image-option",
                  },
                  {
                    label: "Upload Video",
                    icon: "Filmstrip",
                    id: "video-option",
                  },
                  { label: "Upload Text", icon: "File", id: "text-option" },
                ]}
                onOptionSelect={handleOptionSelect}
                className={`w-36 mb-1 font-medium text-sm text-[#878188] bg-[#100f10] border border-solid border-[#2c2a2c] rounded-md z-10 ${dropdownStyle}`}
                customInput
                inputDisplay={inputDisplay}
              />
            </div>
          )}
          <div
            className={`flex relative w-full ${
              !chatPage && !fromDiscoverPage && `px-2`
            }`}
          >
            {!chatPage && !fromDiscoverPage && (
              <div
                className="relative z-10"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={handleDiceClick}
                id="dice-icon"
              >
                <img
                  src="/Randomize.svg"
                  alt="Randomize"
                  className={`mt-[3px] transition duration-300 origin-center cursor-pointer`}
                  style={{
                    filter: `${
                      isHovered || diceIsClicked
                        ? `brightness(2) grayscale(0.8) contrast(1.2) saturate(1.2)`
                        : `brightness(0.8) grayscale(0.8) contrast(1.2) saturate(1.2)`
                    }`,
                    transform: `${
                      isHovered || diceIsClicked
                        ? "rotate(45deg)"
                        : "rotate(0deg)"
                    }`,
                    transition: "filter 0.3 ease, transform 0.3s ease",
                  }}
                />
              </div>
            )}
            <textarea
              id="text-input-field"
              type="text"
              className={`relative text-base font-extralight w-full bg-transparent px-2 ${
                !inputDisplay && fromDiscoverPage ? `mb-6` : !chatPage && `mb-4`
              } border-none focus:outline-none resize-none appearance-none focus:ring-0 overflow-hidden scrollable-content ${
                showSuggestion && localState.nextPromptSuggestion ? "" : "" // Add padding when suggestion is present
              }`}
              value={textPrompt}
              placeholder={
                showSuggestion && localState.nextPromptSuggestion && chatPage
                  ? ``
                  : !inputDisplay && !fromDiscoverPage
                  ? `What music would you like to create?`
                  : fromDiscoverPage
                  ? `What would you like to customize`
                  : `Type your message here...`
              }
              onChange={handleChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (textPrompt?.trim()) {
                    e.preventDefault(); // Prevent default behavior (new line)
                    handleGenerate();
                  } else {
                    // Allow new line if there's no text
                    setInputDisplay(false);
                    e.stopPropagation();
                  }
                } else if (
                  e.key === "Tab" &&
                  showSuggestion &&
                  localState.nextPromptSuggestion
                ) {
                  e.preventDefault();
                  setTextPrompt(localState.nextPromptSuggestion);
                  setShowSuggestion(false);
                  updateLocalState("nextPromptSuggestion", null);
                  posthog.capture("Presses Tab for next suggested prompt");
                }
              }}
              rows={1}
              ref={textareaRef}
              style={{ maxHeight: `${maxHeight}px` }}
              maxLength={MAX_CHARS}
            />

            {showSuggestion &&
              localState.nextPromptSuggestion &&
              !textPrompt &&
              chatPage && (
                <div
                  className="absolute left-2.5 top-0 right-1 flex-wrap items-center justify-items-start text-start gap-1 cursor-auto select-auto"
                  onClick={() => textareaRef.current.focus()} // Focus the textarea on click
                >
                  <span className="text-[#878188] text-normal font-extralight whitespace-pre-wrap break-words">
                    {localState.nextPromptSuggestion}
                  </span>
                  <span
                    className="px-1 w-fit h-5 rounded border border-[#878188] text-[#878188] text-xs ml-1.5"
                    onClick={() => textareaRef.current.focus()}
                  >
                    Tab
                  </span>
                </div>
                // <div class="flex-wrap items-center w-full border rounded-lg px-4 py-2 bg-gray-900 text-white">
                //   <span class="whitespace-pre-wrap break-words">Generate a jazz track with saxophone</span>
                //   <span class="ml-1 bg-gray-700 px-2 py-1 text-sm rounded">Tab</span>
                // </div>
              )}
          </div>

          {inputDisplay && (
            <div className="flex items-center justify-center">
              <CircularProgress value={charCount} maxChars={MAX_CHARS} />

              {localState.responseInProgress ? (
                <div
                  className="p-2 bg-[#ffff] rounded-full cursor-pointer"
                  onClick={handleStopGeneration}
                >
                  <StopFill className="w-4 h-4 text-[#100F10]" />
                </div>
              ) : (
                <>
                  <button
                    id="generate-button"
                    disabled={
                      !((textPrompt && textPrompt.trim()) || file) ||
                      disableGenerate
                    }
                    title={disableGenerate ? "Too long" : ""}
                    onClick={() => handleGenerate()}
                    className={`w-8 h-8 flex flex-row items-center justify-center py-0 px-2 rounded-full ${
                      (textPrompt && textPrompt.trim() && !disableGenerate) ||
                      (file && !disableGenerate)
                        ? "bg-gradient-to-r from-[#fc10f2] to-[#3078e4] hover:from-[#e509e4] hover:to-[#256bb8]"
                        : "text-[#878188] bg-[#4E4A4F] disabled:cursor-not-allowed"
                    }`}
                  >
                    <img
                      className="w-4 relative h-4 overflow-hidden shrink-0"
                      alt=""
                      src={
                        (textPrompt && textPrompt.trim() && !disableGenerate) ||
                        (file && !disableGenerate)
                          ? "/MusicGenerateEnabled.svg"
                          : "/MusicGenerateDisabled.svg"
                      }
                    />
                  </button>
                </>
              )}
            </div>
          )}
        </div>
        {!inputDisplay && (
          <div className="self-stretch flex flex-row items-end justify-between text-sm text-color-grey-40">
            <div className="flex flex-row items-center justify-start relative">
              {!file && !isUploading && !fromDiscoverPage && (
                <Dropdown
                  buttonLabel="Upload reference"
                  options={[
                    {
                      label: "Audio",
                      icon: "MusicNote",
                      id: "audio-option",
                    },
                    {
                      label: "Image",
                      icon: "Picture",
                      id: "image-option",
                    },
                    {
                      label: "Video",
                      icon: "Filmstrip",
                      id: "video-option",
                    },
                    { label: "Text", icon: "File", id: "text-option" },
                  ]}
                  onOptionSelect={handleOptionSelect}
                  className={`p-1 w-32 mb-1 font-medium text-sm text-[#878188] bg-[#100f10] border border-solid border-[#2c2a2c] rounded-md z-10 ${dropdownStyle}`}
                  customInput
                />
              )}
              {isUploading && (
                <div className="flex items-center">
                  <div className="w-3.5 h-3.5 border-2 border-t-2 border-grey-300 border-t-transparent rounded-full animate-spin"></div>
                  <span className="ml-2 text-gray-300">Uploading...</span>
                </div>
              )}
            </div>
            <div className="flex items-center">
              <CircularProgress value={charCount} maxChars={MAX_CHARS} />
              {/* {console.log("localState input.remaining_credits",localState)} */}
              <button
                id="generate-button-2"
                title={disableGenerate ? "Too long" : ""}
                disabled={
                  userData?.remaining_credits === null ||
                  0 ||
                  !((textPrompt && textPrompt.trim()) || file) ||
                  disableGenerate
                }
                onClick={() => handleGenerate()}
                className={`w-8 h-8 flex flex-row items-center justify-center py-0 px-2 rounded-full ${
                  (textPrompt && textPrompt.trim() && !disableGenerate) ||
                  (file && !disableGenerate)
                    ? "bg-gradient-to-r from-[#fc10f2] to-[#3078e4] hover:from-[#e509e4] hover:to-[#256bb8]"
                    : "text-[#878188] bg-[#4E4A4F] disabled:cursor-not-allowed"
                }`}
              >
                <img
                  className="w-4 relative h-4 overflow-hidden shrink-0"
                  alt=""
                  src={
                    (textPrompt && textPrompt.trim() && !disableGenerate) ||
                    (file && !disableGenerate)
                      ? "/MusicGenerateEnabled.svg"
                      : "/MusicGenerateDisabled.svg"
                  }
                />
              </button>
            </div>
          </div>
        )}

        {fromDiscoverPage && audioFile && (
          <div className=" absolute bottom-2 left-2 mt-2 flex items-center text-left">
            {imageSrc && (
              <img
                src={imageSrc}
                alt="Song"
                className="w-8 h-8 object-cover rounded-md mr-2"
              />
            )}
            <div className="flex-1">
              <p className="font-medium text-sm">{audioFile?.name}</p>
              {mood && (
                <p className="font-light text-xs text-gray-400 max-[360px]:max-w-36 max-[400px]:max-w-48 max-[460px]:max-w-52 truncate">
                  {mood}
                </p>
              )}
            </div>
          </div>
        )}

        {!fromDiscoverPage && file && !isUploading && (
          <div className="absolute bottom-2 left-2 flex items-center bg-transparent">
            <div className="flex items-center justify-center mr-1">
              <File className="w-5 h-5 text-white" />
            </div>
            <div className="flex flex-col justify-start">
              <span className="text-white text-sm truncate max-w-[200px]">
                {truncateFileName(file.name, 20)}
              </span>
            </div>
            <button
              onClick={handleFileRemove}
              className="ml-4 text-[#878188] hover:text-white text-lg"
            >
              <Xmark />
            </button>
          </div>
        )}
      </div>
      {modalContent && (
        <Modal closeModal={() => setModalContent(null)} showCloseButton={false}>
          <ModalContent
            closeModal={() => {
              toastMessage === "File size exceeds the 20 MB limit." &&
                setToastMessage("");
              setModalContent(null);
            }}
            title={modalMapping[getModalKey(modalContent)].title}
            fileTypes={modalMapping[getModalKey(modalContent)].fileTypes}
            handleFileChange={handleFileChange}
            toastMessage={toastMessage}
            setToastMessage={setToastMessage}
          />
        </Modal>
      )}
      {showToast && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage("")}
        />
      )}
    </div>
  );
};

export default CustomInput;
