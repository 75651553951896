import React, { useState, useEffect } from "react";

const ChatBoxLoader = ({ className, giminiResponseLoader = false }) => {
  const loadingTexts = [
    "Reading between the beats...",
    "Let's see what magic your words hold...",
    "Turning your thoughts into a melody of ideas...",
    "Unlocking the story behind your prompt...",
    "Exploring the world you're imagining...",
    "Piecing together your creative puzzle...",
    "Finding the groove in your request...",
    "Mapping out your vision with precision...",
    "Composing the perfect harmony for your vision...",
    "Orchestrating your ideas into a masterpiece...",
    "Letting the rhythm of your words guide us...",
    "Blending sounds to match your imagination...",
    "Infusing emotion into every note...",
    "Fine-tuning your inspiration into melody...",
    "Weaving soundscapes from your creativity...",
    "Building your sonic masterpiece...",
    "Syncing with your artistic flow...",
    "Crafting music that moves with your story...",
    "Translating your imagination into sound...",
    "Brewing a symphony from your ideas...",
    "Designing the perfect soundtrack for you...",
    "Listening to the echoes of your creativity...",
    "Shaping vibrations into a sonic experience...",
    "Harmonizing with your artistic vision...",
    "Sketching melodies from your inspiration...",
    "Tuning into your creative wavelength...",
    "Discovering hidden rhythms in your request...",
    "Sculpting sounds that resonate with you...",
    "Mixing your thoughts into pure audio magic...",
    "Bringing your sonic dreams to life...",
    "Creating a tune as unique as you...",
    "Your vision, our melody—let’s make music...",
    "Transforming silence into a masterpiece...",
    "Decoding the soundtrack of your thoughts...",
    "Aligning beats with your creativity...",
    "Filling the silence with inspiration...",
    "Giving your ideas a musical heartbeat...",
    "Your creativity, our composition...",
    "Echoing your vision in every note...",
    "Every word you type, a song in the making...",
  ];

  const [currentText, setCurrentText] = useState("");
  const [availableTexts, setAvailableTexts] = useState([...loadingTexts]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    // Initial text selection
    if (availableTexts.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableTexts.length);
      const selectedText = availableTexts[randomIndex];
      setCurrentText(selectedText);

      // Remove the selected text from available texts
      setAvailableTexts((prev) => prev.filter((text) => text !== selectedText));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // If all texts have been used, reset the available texts
      if (availableTexts.length === 0) {
        setAvailableTexts([...loadingTexts]);
      }

      // Select a random text from available texts
      if (availableTexts.length > 0) {
        const randomIndex = Math.floor(Math.random() * availableTexts.length);
        const selectedText = availableTexts[randomIndex];

        setCurrentText(selectedText);

        // Remove the selected text from available texts
        setAvailableTexts((prev) =>
          prev.filter((text) => text !== selectedText)
        );

        // Update key to restart animation
        setKey((prev) => prev + 1);
      }
    }, 4000); // Match this with the CSS animation duration

    return () => clearInterval(interval);
  }, [availableTexts]);

  const styles = `
    .spotlight-text {
      position: relative;
      overflow: hidden;
      background: linear-gradient(
        90deg,
        #444444,
        #444444 45%,
        #ffffff 48%,
        #444444 52%,
        #444444
      );
      background-size: 400% 100%;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: spotlight 28s linear;
    }

    @keyframes spotlight {
      0% {
        background-position: 250% 0;
      }
      100% {
        background-position: -500% 0;
      }
    }
  `;

  return (
    <>
      <style>{styles}</style>
      <div
        className={`mb-6 w-full flex items-start justify-start hover:[background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] transition duration-500 p-[12px] rounded-lg group relative ${className}`}
      >
        <img
          src="/logo.png"
          alt="AI Avatar"
          className="w-8 h-8 rounded-full bg-[#2c2a2c] p-1"
        />
        {giminiResponseLoader ? (
          <div className="flex flex-col">
            <div className="w-full ml-3">
              <div
                key={key}
                className="spotlight-text text-base font-normal py-1 pb-2.5 px-0.5"
              >
                {currentText}
              </div>
            </div>
            <div className="flex-1 ml-3">
              <div className="px-0.5 max-w-full w-full">
                <div className="flex items-center">
                  <div className="relative w-8 h-8 bg-[#2c2a2c] rounded flex items-center justify-center"></div>
                  <div className="flex flex-col gap-2">
                    <div className="flex flex-col ml-1.5 space-y-1">
                      <div className="w-60 h-3 bg-[#3c393c] relative rounded-full overflow-hidden animate-pulse">
                        <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
                      </div>
                      <div className="w-16 h-2.5 bg-[#3c393c] relative overflow-hidden rounded-full animate-pulse">
                        <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1 ml-3">
            <div className="rounded-lg px-0.5 max-w-full w-full">
              <div className="md:w-[30rem] w-full my-1.5 h-3 bg-[#3c393c] rounded-full relative overflow-hidden animate-pulse">
                <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
              </div>
              <div className="flex items-center">
                <div className="relative w-8 h-8 bg-[#2c2a2c] rounded-md flex items-center justify-center"></div>
                <div className="flex flex-col gap-2">
                  <div className="flex flex-col ml-1.5 space-y-1">
                    <div className="w-60 h-2.5 bg-[#3c393c] rounded-full relative overflow-hidden animate-pulse">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
                    </div>
                    <div className="w-20 h-2 bg-[#3c393c] rounded-full relative overflow-hidden animate-pulse">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#3c393c] to-transparent animate-progress"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBoxLoader;
