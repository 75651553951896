import React, { useState, useEffect, useRef } from "react";
import { Xmark, PencilToLine } from "@gravity-ui/icons";
import Version from "./Version";
import EditDetailsPanel from "./EditDetailsPanel";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { version } from "@mui/material";
import { usePostHog } from "posthog-js/react";

const DetailsPanel = ({
  className,
  onClose,
  projectDetails,
  onUpdateDetails,
}) => {
  const { localState } = useLocalState();
  const posthog = usePostHog();

  const [isEditing, setIsEditing] = useState(false);
  const [details, setDetails] = useState(
    localState.currentSongDetails || projectDetails
  );
  const [versionHistory, setVersionHistory] = useState([]);
  const versionHistoryRef = useRef(null);

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  // Handle updates to details when state changes
  useEffect(() => {
    if (localState.versionHistory) {
      const currentVersion = localState.versionHistory.find((v) =>
        v.versions?.some(
          (version) => version.ai_response_id === version.ai_response_id
        )
      );

      if (currentVersion) {
        setVersionHistory(localState.versionHistory);
      }
    }
  }, [localState.versionHistory]);

  // Scroll to the bottom when version history is updated
  useEffect(() => {
    if (versionHistoryRef.current) {
      versionHistoryRef.current.scrollTop =
        versionHistoryRef.current.scrollHeight;
    }
  }, [versionHistory]);

  // Handle updates to details when state changes
  useEffect(() => {
    if (localState.currentSongDetails) {
      setDetails(localState.currentSongDetails);
    } else {
      setDetails(projectDetails);
    }
  }, [localState.currentSongDetails]);

  const handleEditClick = () => {
    posthog.capture("Click on Edit Details");
    setIsEditing(true);
  };

  const handleSave = (updatedDetails) => {
    setDetails(updatedDetails);
    setIsEditing(false);
    if (onUpdateDetails) {
      onUpdateDetails(updatedDetails);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  useEffect(() => {
    if (localState.currentPlayingResponseId) {
      const versionElement = document.getElementById(
        `version-${localState.currentPlayingResponseId}`
      );
      if (versionElement && versionHistoryRef.current) {
        versionHistoryRef.current.scrollTo({
          top: versionElement.offsetTop - versionHistoryRef.current.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, [localState.currentPlayingResponseId]);

  return isEditing ? (
    <EditDetailsPanel
      className={className}
      projectDetails={details}
      onSave={handleSave}
      onCancel={handleCancel}
      onClose={onClose}
    />
  ) : (
    <div className={className}>
      <div className="flex items-center justify-between px-4 py-3">
        <h2 className="text-lg font-normal">View Details</h2>
        <button
          className="text-[#878188] hover:text-white p-2 rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
          onClick={onClose}
        >
          <Xmark />
        </button>
      </div>
      <div className="w-full border-t border-t-[#1d1b1d]"></div>

      <div className="flex flex-col items-center mb-5 mx-5 mt-5">
        <div className="relative">
          <img
            src={details.coverImage}
            alt="Cover"
            className="w-24 h-24 object-cover rounded-md mb-3"
          />
        </div>

        {!details?.state && (
          <div className="text-center align-center justify-center items-center">
            <div className="flex justify-center items-center gap-1 mb-0.5 pl-8">
              <h3 className="font-extralight text-xl w-fit line-clamp-1">
                {details.name}
              </h3>

              <button
                className="text-[#878188] hover:text-white p-2 rounded-full hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]"
                onClick={handleEditClick}
              >
                <PencilToLine />
              </button>
            </div>
            <p className="font-extralight self-center text-sm text-[#a5a0a6] w-fit line-clamp-1">
              {details.description}
            </p>
          </div>
        )}
      </div>

      {!isEditing && (
        <div className="">
          <h4 className="font-normal text-base px-2.5 border-b border-b-[#2C2A2C] pb-2.5">
            History
          </h4>
          <div
            className={`p-2 flex-col ${
              isAudioPlaying &&
              (versionHistory?.some((group) => group.versions?.length >= 2) ||
                versionHistory?.length > 4)
                ? `max-h-[calc(100vh-395px)] scrollable-version overflow-y-auto`
                : (!isAudioPlaying &&
                    versionHistory?.length > 1 &&
                    versionHistory?.some(
                      (group) => group.versions?.length > 2
                    )) ||
                  (!isAudioPlaying &&
                    versionHistory?.length === 1 &&
                    versionHistory?.some(
                      (group) => group.versions?.length > 3
                    )) ||
                  versionHistory?.length > 4
                ? `max-h-[calc(100vh-330px)] scrollable-version overflow-y-auto`
                : "overflow-visible"
            }`}
            ref={versionHistoryRef}
          >
            {versionHistory
              // For each group, remove stopped versions from the versions array.
              .map((group) => ({
                ...group,
                versions: group.versions.filter(
                  (version) => !version.isStopped
                ),
              }))
              // Only keep groups that still have at least one version.
              .filter((group) => group.versions.length > 0)
              .map((versionGroup, index) => (
                <Version
                  key={index}
                  version={versionGroup}
                  versionCount={versionHistory?.length - 1}
                  index={index}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailsPanel;
