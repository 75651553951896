import React from "react";
import { useState, useRef, useEffect } from "react";
import {
  ChevronLeft,
  ChevronRight,
  PlayFill,
  PauseFill,
  ThumbsUpFill,
  ThumbsUp,
} from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import useSidebarState from "../hooks/useSidebarState";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { usePostHog } from "posthog-js/react";
import { getCookie } from "../../utils/cookieUtils";
import apiService from "../../api/apiService";
import Modal from "../modal/Modal";
import CustomInput from "./CustomInput";

const LoadingStandardCard = () => (
  <div className="relative w-[180px] flex flex-col gap-2 pb-3 animate-pulse">
    <div className="relative self-stretch h-44 p-2">
      <div className="absolute inset-0 w-full h-full bg-[#2C2A2C] rounded-lg" />
    </div>
    <div className="flex flex-col self-stretch px-1 gap-1">
      <div className="h-5 bg-[#2C2A2C] rounded w-3/4" />
      <div className="h-4 bg-[#2C2A2C] rounded w-1/2" />
    </div>
  </div>
);

const StandardCard = ({ card }) => {
  const posthog = usePostHog();
  const [isPlaying, setIsPlaying] = useState(false);
  const {
    isPlaying: isAudioPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    setDiscoverId,
  } = useAudioPlayer();
  const { localState, updateLocalState } = useLocalState();
  const [likeStatus, setLikeStatus] = useState(card?.isLiked || false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);

  useEffect(() => {
    if (card && localState.forYouTracks) {
      const track = localState.forYouTracks.find((t) => t.id === card.id);
      if (track) {
        setLikeStatus(track.isLiked);
      }
    }
  }, [card, localState.forYouTracks]);

  const handlePlayPause = (e) => {
    posthog.capture("Trending Song Click to Play", { card: card?.title });
    e.stopPropagation();
    setDiscoverId(card.id);

    if (!card.audioSrc) {
      setToastMessage("No audio available for this project.");
      setToastType("info");
      return;
    }

    if (isAudioPlaying && currentAudioSrc === card.audioSrc) {
      pauseAudio();
    } else if (currentAudioSrc === card.audioSrc) {
      resumeAudio();
    } else {
      playAudio(card.audioSrc, card.title, card.description, card.image);
    }
  };

  const handleLike = async (trackId, trackTitle) => {
    const userId = getCookie("user_id");

    if (!userId || !trackId) return;

    const payload = {
      discover_id: trackId,
      user_id: userId,
      is_like: !likeStatus, // Toggle like status
    };

    try {
      if (likeStatus) {
        // If already liked, remove the like
        await apiService.sendRequest("removeDiscoverLike", payload);
        setLikeStatus(false);

        const currentLikedTracks = localState.likedDiscoverTracks || [];
        const updatedLikedTracks = currentLikedTracks.filter(
          (track) => track.id !== trackId
        );
        updateLocalState("likedDiscoverTracks", updatedLikedTracks);

        posthog.capture("Discover Song Click to Unlike", {
          song: trackTitle,
        });

        // Update forYouTracks in LocalState
        const currentTracks = localState.forYouTracks || [];
        const updatedTracks = currentTracks.map((track) =>
          track.id === trackId ? { ...track, isLiked: false } : track
        );
        updateLocalState("forYouTracks", updatedTracks);
      } else {
        // Add the like
        await apiService.sendRequest("updateDiscoverLike", payload);
        setLikeStatus(true);

        // Add the newly liked song to likedDiscoverTracks in LocalState
        const likedTrack = localState.forYouTracks.find(
          (track) => track.id === trackId
        );
        posthog.capture("Discover Page Recommended Song Click to Like", {
          song: trackTitle,
        });

        if (likedTrack) {
          const currentLikedTracks = localState.likedDiscoverTracks || [];
          const updatedLikedTrack = { ...likedTrack, isLiked: true };

          if (localState.likedDiscoverTracks) {
            updateLocalState("likedDiscoverTracks", [
              ...currentLikedTracks,
              updatedLikedTrack,
            ]);
          } else {
            updateLocalState("likedDiscoverTracks", [updatedLikedTrack]);
          }

          // Update forYouTracks in LocalState
          const currentTracks = localState.forYouTracks || [];
          const updatedTracks = currentTracks.map((track) =>
            track.id === trackId ? { ...track, isLiked: true } : track
          );
          updateLocalState("forYouTracks", updatedTracks);
        }
      }
    } catch (error) {
      console.error("Error updating like:", error);
    }
  };

  const handleOpenModal = async (song, e) => {
    if (!getCookie("user_id")) {
      updateLocalState("showLoginModal", (prev) => !prev);
      return;
    }

    if (!song.id) return;

    posthog.capture("Click to Customize Recommended Audio in Discover Page", {
      song: song?.title,
    });

    const songData = {
      audioFile: {
        name: song.title,
        type: "audio/wav",
        url: `gs://discover_songs/${song.fileName}`,
      },
      image: song.image,
      mood: song.mood,
    };

    setSelectedSong(songData);
    setIsModalOpen(true);
  };

  return (
    <>
      <div
        onClick={handlePlayPause}
        className="relative w-[180px] flex flex-col cursor-pointer gap-2 pb-3 grow transform duration-500 rounded-xl group"
      >
        <div className="relative self-stretch h-44 p-2 overflow-hidden">
          <img
            src={card.image}
            alt={card.title}
            className="absolute inset-0 w-full h-full object-cover rounded-lg border border-[#3C393C]"
          />

          <div className="absolute top-2 right-2 flex gap-2 transform transition-transform duration-300 md:-translate-y-[150%] md:group-hover:translate-y-0">
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleOpenModal(card);
              }}
              className="p-2 bg-[#2C2A2C] hover:bg-[#3c393c] text-white/40 hover:text-white rounded-full"
              disabled={localState.userProfileData?.remaining_credits <= 0}
            >
              <img src="/Generate.svg" className="w-4 h-4" />
            </button>

            <button
              onClick={(e) => {
                e.stopPropagation();
                handleLike(card.id, card.title);
              }}
              className="p-2 bg-[#2C2A2C] hover:bg-[#3c393c] text-white/40 hover:text-white rounded-full"
            >
              {likeStatus ? (
                <ThumbsUpFill className="w-4 h-4" />
              ) : (
                <ThumbsUp className="w-4 h-4" />
              )}
            </button>
          </div>

          <button
            onClick={handlePlayPause}
            className={`absolute bottom-2 left-2 p-3 flex justify-center items-center gap-1 bg-white text-[#100f10] rounded-full transform ${
              isAudioPlaying && currentAudioSrc === card.audioSrc
                ? "translate-y-0"
                : "transform translate-y-[150%] group-hover:translate-y-0"
            } transition-transform duration-300 ease-in-out`}
          >
            {isAudioPlaying && currentAudioSrc === card.audioSrc ? (
              <PauseFill />
            ) : (
              <PlayFill />
            )}
          </button>
        </div>
        <div className="flex flex-col self-stretch px-1">
          <span className="font-normal text-base text-white">{card.title}</span>
          <span className="font-extralight text-sm text-[#c5c3c6] truncate">
            {card.description}
          </span>
        </div>
      </div>
      {isModalOpen && selectedSong && (
        <Modal
          closeModal={() => setIsModalOpen(false)}
          showCloseButton={false}
          easyClose={true}
        >
          <CustomInput
            audioFile={selectedSong.audioFile}
            imageSrc={selectedSong.image}
            mood={selectedSong.mood}
            fromDiscoverPage={true}
            className="w-full md:w-[40rem]"
            requestData={{ createProject: true }}
            chatPage={false}
          />
        </Modal>
      )}
    </>
  );
};

const VinylCard = ({ card, onCardClick }) => {
  return (
    <div
      onClick={() => onCardClick && onCardClick(card)}
      key={card.id}
      className="flex-none group cursor-pointer"
    >
      <div className="relative transition-all duration-700 w-[180px] group-hover:w-[260px] overflow-visible">
        <div
          className={`bg-card ${card.background} rounded-lg overflow-hidden w-[180px] relative z-10`}
        >
          <img
            src="/CircularPattern.svg?height=300&width=300"
            alt={card.title}
            style={{ filter: "brightness(0.85)" }}
            className="w-full h-[200px] object-cover"
          />
          <div className="absolute darker-filter left-[10%] top-[9%] z-10 w-[9rem] h-[9rem]">
            <img
              src={card.image}
              alt="Center Icon"
              className="w-full h-full rounded-full"
            />
          </div>
          <div className="">
            <h3 className="absolute left-2 bottom-2 text-md font-medium">
              {card.title}
            </h3>
          </div>
        </div>
        <div className="absolute top-1/2 left-[4%] transform -translate-y-1/2 transition-all duration-700 ease-in-out group-hover:left-[28%] group-hover:rotate-[60deg]">
          <div className="w-50 h-50 rounded-full">
            <img src="/Vinyl.svg" />
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoCard = ({ card, onCardClick }) => {
  const videoRef = useRef(null);
  const debounceTimeout = useRef(null);

  const handleMouseEnter = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      }
    }, 100); // Add a small delay to debounce
  };

  const handleMouseLeave = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }, 100); // Add a small delay to debounce
  };

  return (
    <div
      onClick={() => onCardClick && onCardClick(card)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      key={card.id}
      className="flex-none group cursor-pointer"
    >
      <div className="relative transition-all duration-700 w-[180px] group-hover:w-[320px] overflow-hidden rounded-lg">
        <div className="bg-card rounded-lg overflow-hidden relative z-10 w-full h-[200px]">
          {/* <img
            src="/design.jpg"
            alt={card.title}
            style={{ filter: "brightness(0.85)" }}
            className="w-full h-full object-cover absolute top-0 left-0 transition-opacity duration-300 group-hover:opacity-0"
          /> */}
          <video
            ref={videoRef}
            src={card.videoSrc} // Replace with the actual video source
            loop
            muted
            playsInline
            className="absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 group-hover:opacity-100"
          ></video>
        </div>
        <div className="my-2">
          <h3 className="text-md font-normal text-white">{card.title}</h3>
        </div>
      </div>
    </div>
  );
};

const CARD_COMPONENTS = {
  standard: StandardCard,
  vinyl: VinylCard,
  video: VideoCard,
};

const HorizontalCards = ({
  cardType = "standard",
  data = [],
  title,
  exploreMoreText = null,
  onExploreClick,
  onCardClick,
  isLoading,
}) => {
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [isShortSidebar] = useSidebarState();
  const { localState } = useLocalState();
  const CardComponent = CARD_COMPONENTS[cardType];
  const [isLongSidebar, setIsLongSidebar] = useState(!isShortSidebar);
  const [isInitialRender, setIsInitialRender] = useState(true);

  const updateArrowVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    if (!isInitialRender) {
      setIsLongSidebar(localState.longSidebar);
    } else {
      setIsInitialRender(false);
    }
  }, [localState.longSidebar]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", updateArrowVisibility);
      updateArrowVisibility();
    }
    return () =>
      scrollContainer?.removeEventListener("scroll", updateArrowVisibility);
  }, []);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300;
      const newScrollLeft =
        direction === "left"
          ? scrollContainerRef.current.scrollLeft - scrollAmount
          : scrollContainerRef.current.scrollLeft + scrollAmount;

      scrollContainerRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="flex flex-col w-full h-full px-4 pt-3">
      <div className="flex w-full flex-row justify-between items-center">
        <h1 className="text-2xl font-normal text-white">{title}</h1>
        {exploreMoreText && (
          <button
            onClick={onExploreClick}
            className="flex flex-row items-center gap-2 text-sm hover:text-white duration-200 text-[#878188] hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] h-9 px-3 rounded-full"
          >
            {exploreMoreText} <ChevronRight className="w-4 h-4" />
          </button>
        )}
      </div>
      <div className="flex relative w-full">
        {/* Scrolling container */}
        <div
          className={`flex flex-row w-full ${
            isLongSidebar
              ? "md:max-w-[calc(100vw-276px)]"
              : "md:max-w-[calc(100vw-135px)]"
          }  overflow-hidden`}
        >
          <div
            ref={scrollContainerRef}
            className="flex overflow-x-auto space-x-4 py-4 scrollbar-hide "
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {isLoading
              ? [...Array(5)].map((_, index) => (
                  <LoadingStandardCard key={index} />
                ))
              : data.map((item) => (
                  <div key={item.id} className="flex-none">
                    <CardComponent card={item} onCardClick={onCardClick} />
                  </div>
                ))}
          </div>
          {showLeftArrow && (
            <button
              variant="outline"
              size="icon"
              className="absolute -left-2 top-1/2 transform -translate-y-1/2 bg-background/80 backdrop-blur-sm z-10 p-2 rounded-full bg-white"
              onClick={() => scroll("left")}
              aria-label="Scroll left"
            >
              <ChevronLeft className="h-4 w-4 text-black" />
            </button>
          )}
          {showRightArrow && (
            <button
              variant="outline"
              size="icon"
              className="absolute -right-2 top-1/2 transform -translate-y-1/2 bg-background/80 backdrop-blur-sm z-10 p-2 rounded-full bg-white"
              onClick={() => scroll("right")}
              aria-label="Scroll right"
            >
              <ChevronRight className="h-4 w-4 text-black" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HorizontalCards;
