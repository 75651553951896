import React, { useEffect, useState } from "react";
import { Bell } from "@gravity-ui/icons";
import Notifications from "./ResponsiveNotification";
import apiService from "../../api/apiService";
import { getCookie } from "../../utils/cookieUtils";
import { io } from "socket.io-client";
import { useAuth } from "../../contexts/AuthContext";
const socket = io(process.env.REACT_APP_SERVER_ENDPOINT);

const NotificationButton = () => {
  const { isLoggedIn } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const userId = getCookie("user_id");
  const [notificationCount, setNotificationCount] = useState(0);

  // Add click handler for document
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside notification area
      const notificationBox = document.querySelector(".notification-container");
      if (
        notificationBox &&
        !notificationBox.contains(event.target) &&
        !event.target.closest(".notification-trigger")
      ) {
        setOpenModal(false);
        setNotificationCount(0);
      }
    };

    // Add event listener when modal is open
    if (openModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openModal]);

  useEffect(() => {
    if (!isLoggedIn) return;

    getNotifications();
    // Connect socket on component mount
    socket.connect();

    // Listen for messages
    socket.on(`${userId}-notification`, (data) => {
      getNotifications();
      // console.log("Notification received", data);
    });

    // Cleanup on unmount
    return () => {
      socket.disconnect();
    };
  }, []);

  const getNotifications = async () => {
    const data = await apiService.handleProtectedRoute(
      "fetchNewNotifications",
      {}
    );
    setNotificationCount(data.notificationCount);
  };

  useEffect(() => {}, [notificationCount]);

  return (
    <div className="">
      <div className="flex gap-2">
        <button
          onClick={() => setOpenModal((prev) => !prev)}
          className="relative flex items-center justify-center p-[10px] rounded-full bg-[#2c2a2c] hover:bg-[#3C393C] cursor-pointer"
        >
          <img
            src="/Notificationicon.svg"
            alt="Notification Icon"
            className="w-5 h-5 object-contain"
          />
          {notificationCount > 0 && (
            <div
              className="absolute top-0 right-0 transform translate-x-1/1 -translate-y-1/1 w-5 h-5 bg-gradient-to-r from-[#FC10F2] to-[#3078E4] rounded-full flex items-center justify-center text-xs text-white font-bold"
              style={{
                background: "linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
              }}
            >
              {notificationCount}
            </div>
          )}
        </button>
      </div>

      {/* Notifications Modal */}
      {openModal && (
        <div
          className="absolute inset-2 bg-opacity-10 flex justify-center items-center z-50"
          onClick={() => {
            setNotificationCount(0);
            setOpenModal(false);
          }} // Close modal when clicking outside
        >
          <div
            className="notification-container relative left-[0px] bottom-16 text-white rounded-lg shadow-lg w-full max-w-md"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <Notifications onClose={() => setOpenModal(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationButton;
