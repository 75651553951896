import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "../modal/Modal"; // Import Modal component
import { componentMapper } from "../../utils/componentMapper"; // Import componentMapper
import {
  FaTiktok,
  FaPlus,
  FaBars,
  FaSearch,
  FaSignInAlt,
  FaUserPlus,
  FaQuestionCircle,
  FaUserAlt,
  FaHome,
} from "react-icons/fa";
import { RiExpandRightLine, RiContractLeftLine } from "react-icons/ri";
import { GrProjects } from "react-icons/gr";
import { PiNotePencil, PiSquaresFour } from "react-icons/pi";
import {
  House,
  Globe,
  ArrowRightFromSquare,
  MusicNote,
  ArrowLeftFromLine,
  CircleQuestionFill,
  Plus,
  Circles4Square,
  ArrowRightFromLine,
  ChevronDown,
  ChevronUp,
  Person,
  Rectangles4,
  Rocket,
  CircleExclamation,
  Keyboard,
  Persons,
  Gear,
  PencilToLine,
  PersonPlus,
  ThumbsUp,
  Bars,
  File,
  Comments,
} from "@gravity-ui/icons";
import { useAuth } from "../../contexts/AuthContext";
import { useLocalState } from "../../contexts/LocalStateProvider";
import AudioBars from "../custom_components/AudioBars";
import { usePostHog } from "posthog-js/react";
import {
  setRedirectAfterSelectedPlan,
  setSubscriptionModal,
  setSubscriptionTypeModal,
} from "../../store/reducers/subscriptionSlice";
import { useDispatch } from "react-redux";

/* 
  This small modal component loads Zapier's script and displays the chatbot embed.
  We define it here so we can render it conditionally within Button.js whenever the 
  "Chat with us" dropdown option is clicked.
*/
function ZapierChatModal({ closeModal }) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);
  return (
    <zapier-interfaces-chatbot-embed
      is-popup="true"
      chatbot-id="cm6914edy0092128fcfo820q7"
    />
  );
}

const iconComponents = {
  Comments,
  FaTiktok,
  FaPlus,
  FaBars,
  FaSearch,
  FaSignInAlt,
  FaUserPlus,
  FaQuestionCircle,
  RiExpandRightLine,
  RiContractLeftLine,
  GrProjects,
  FaUserAlt,
  PiNotePencil,
  House,
  Globe,
  ArrowRightFromSquare,
  MusicNote,
  ArrowLeftFromLine,
  CircleQuestionFill,
  Plus,
  PiSquaresFour,
  Circles4Square,
  ArrowRightFromLine,
  ChevronDown,
  ChevronUp,
  Person,
  Rectangles4,
  Rocket,
  CircleExclamation,
  Keyboard,
  Persons,
  Gear,
  PencilToLine,
  PersonPlus,
  ThumbsUp,
  Bars,
  File,
};

export default function Button(props) {
  const posthog = usePostHog();
  const {
    caption,
    path,
    url,
    className,
    icon,
    btnStyle,
    imgStyle,
    onClick,
    image,
    dropdownOptions,
    dropdownStyle,
    isSuggestion,
    iconStyle,
    svg,
    dropdownClick,
    musicEmoji,
    closeModal,
    createNew,
    trigger,
    renderComponents,
    onClickOption,
    signOut,
    id,
    userAvatar,
    tabPath,
    imgClassName,
    hoverImage,
    hoverVideo,
    hoverAudio,
    hoverImageStyle,
    hoverVideoStyle,
    tooltip,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { logout } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { localState, updateLocalState } = useLocalState();
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();
  const [showZapierChat, setShowZapierChat] = useState(false);

  const IconComponent = icon ? iconComponents[icon] : null;
  const activeClassName = "text-white";
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(location.pathname === path);
  }, [location, path]);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClick = (e) => {
    if (id === "help-button") {
      setShowZapierChat(true);
      posthog.capture("Click on Help icon to open Zapier chatbot");
    }
    if (caption === "Sign Up") {
      posthog.capture("Click on Sign Up Button");
    }
    if (caption === "Forgot Password?") {
      posthog.capture("Click on Forgot Password Button");
    }
    if (id === "sidebar-link-sign-in") {
      posthog.capture("Click on Sign In Button");
    } else if (caption === "Join the waitlist") {
      posthog.capture("Click on Join the Waitlist Button");
    }
    if (id === "sidebar-link-invite-new-people") {
      posthog.capture("Click on Invite new people");
    }
    if (id === "sidebar-link-create-team") {
      posthog.capture("Click on Create Team");
    }

    if (id === "sidebar-link-create-team") {
      posthog.capture("Click Create New Team");

      dispatch(setRedirectAfterSelectedPlan("/create-team"));
      dispatch(setSubscriptionModal(true));
      // localState.userProfileData?.remaining_credits == 0 ? dispatch(setSubscriptionModal(true)) : navigate("/create-team");
    }

    // For sidebar-create-new-button-label
    if (id === "sidebar-create-new-button-label") {
      if (localState?.userProfileData?.remaining_credits == 0) {
        if (localState.isAdmin && localState.isTeamMember) {
          dispatch(setRedirectAfterSelectedPlan("/create-team"));
          dispatch(setSubscriptionModal(true));
          dispatch(setSubscriptionTypeModal("upgrade"));
        } else if (!localState.isTeamMember) {
          dispatch(setRedirectAfterSelectedPlan("/create-team"));
          dispatch(setSubscriptionModal(true));
        }
        return;
      }

      posthog.capture("Click Create New Project");
    }

    // For sidebar-link-home
    if (id === "sidebar-link-home") {
      posthog.capture("Click Home Navigation");
    }

    // For sidebar-link-projects
    if (id === "sidebar-link-projects") {
      posthog.capture("Click Projects Navigation");
    }

    // For sidebar-link-discover
    if (id === "sidebar-link-discover") {
      posthog.capture("Click Discover Navigation");
    }

    // For sidebar-link-liked-tracks
    if (id === "sidebar-link-liked-tracks") {
      posthog.capture("Click Liked Tracks Navigation");
    }

    if (id === "sidebar-link-feedback-form") {
      posthog.capture("Click to open Feedback Form");
    }
    if (id === "sidebar-settings-button") {
      posthog.capture("Click Settings Icon");
    }
    if (id === "sidebar-link-help") {
      posthog.capture("Click Help Navigation");
    }
    if (id === "footer-link-about") {
      posthog.capture("Click on About link footer");
    }
    if (id === "sidebar-link-features") {
      posthog.capture("Click on Features link footer");
    }
    if (id === "sidebar-link-resources") {
      posthog.capture("Click on Resources link footer");
    }
    if (id === "sidebar-link-contact") {
      posthog.capture("Click on Contact link footer");
    }

    if (trigger === "closeModal") {
      // Close the modal if the trigger is closeModal
      onClick && onClick();

      if (signOut) {
        logout();
        navigate("/");
      }
    }

    if (dropdownOptions) {
      e.stopPropagation();
      setDropdownVisible(!dropdownVisible);
    }

    if (onClick === "handleSignOut") {
      logout();
      navigate("/");
      return;
    }

    if (onClick) {
      onClick(e);
      if (icon === "ArrowRightFromLine") updateLocalState("longSidebar", true);
      else if (icon === "ArrowLeftFromLine")
        updateLocalState("longSidebar", false);
    }

    if (isSuggestion) {
      posthog.capture("Click on Suggestion Prompt Button");

      const mediaData = {
        text: caption,
      };

      const fetchFileAsBinary = async (filePath, type) => {
        const response = await fetch(filePath);
        const arrayBuffer = await response.arrayBuffer();
        return new File([arrayBuffer], filePath.split("/").pop(), { type });
      };

      const handleMediaFile = async () => {
        if (hoverVideo) {
          const videoFile = await fetchFileAsBinary(
            "/Minecraft.mp4",
            "video/mp4"
          );
          mediaData.file = {
            name: hoverVideo.split("/").pop(),
            type: "video/mp4",
            file: videoFile,
            url: "gs://wubble-storage/audio/user-uploads/Minecraft.mp4",
          };
        } else if (hoverAudio) {
          const audioFile = await fetchFileAsBinary(
            "/Sunset Lofi.wav",
            "audio/wav"
          );
          mediaData.file = {
            name: hoverAudio.split("/").pop(),
            type: "audio/wav",
            file: audioFile,
            url: "gs://wubble-storage/audio/user-uploads/Sunset Lofi.wav",
          };
        } else if (hoverImage) {
          const imageFile = await fetchFileAsBinary("/Coffee.png", "image/png");
          mediaData.file = {
            name: hoverImage.split("/").pop(),
            type: "image/png",
            file: imageFile,
            url: "gs://wubble-storage/audio/user-uploads/Coffee.png",
          };
        }

        updateLocalState("suggestionClicked", mediaData);
      };

      handleMediaFile();
    }

    if (path) {
      posthog.capture("Navigation", { destination: path });
      navigate(path);
    }

    if (userAvatar && pageWidth < 768) {
      posthog.capture("Navigation", { destination: "/settings" });
      navigate("/settings");
    }
    if (tabPath && tabPath === "organization") {
      posthog.capture("Navigation", { destination: "/settings" });
      updateLocalState("organizationClicked", true);
      navigate("/settings");
    } else if (tabPath && tabPath === "organizationInvite") {
      posthog.capture("Navigation", { destination: "/settings" });
      updateLocalState("organizationClicked", true);
      updateLocalState("organizationInviteClicked", true);
      navigate("/settings");
    }

    if (url) {
      posthog.capture("External Navigation", { destination: url });
      window.location.href = url; // Navigate to external URL
    }
  };

  const handleDropdownClick = (option) => {
    // If user clicked "Chat with us", show the Zapier chatbot modal
    if (option?.id === "dropdown-chat-with-us") {
      setDropdownVisible(false);
      setShowZapierChat(true);
      return;
    }

    posthog.capture("Click on Dropdown Option", { option: option.label });
    if (option?.url) {
      posthog.capture("External Navigation", { destination: option.url });
      window.location.href = option.url; // Navigate to external URL
    } else if (path) {
      posthog.capture("Navigation", { destination: path });
      navigate(path); // Navigate within the app
    } else if (option.path) {
      posthog.capture("Navigation", { destination: option.path });
      navigate(option.path);
    } else if (onClickOption) {
      posthog.capture("Navigation", { destination: option.path });
      onClickOption();
    }
    setDropdownVisible(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
      setShowZapierChat(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const DropdownIcon = dropdownVisible ? ChevronUp : ChevronDown;

  const userAgent = navigator.userAgent.toLowerCase();

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <button
        id={id}
        style={btnStyle}
        className={`${className} ${isActive && activeClassName}`}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <>
          {image && (
            <img
              style={imgStyle}
              src={image}
              alt={caption}
              className={`${imgClassName ? imgClassName : `w-6 h-6`}`}
            />
          )}
          {svg && <img src={svg} alt="icon" className={`${iconStyle}`} />}
          {IconComponent && <IconComponent className={`${iconStyle}`} />}
          {musicEmoji && (
            <div className="ml-1 h-7 w-7 px-1.5 py-1 [background:var(--Color-Opacity-Primary-10,rgba(186,24,236,0.10))] rounded-sm text-center text-sm">
              {musicEmoji}
            </div>
          )}
          {caption && <div className="truncate max-w-80">{caption}</div>}
          {tooltip && isHovered && (
            <div
              className={`absolute ${
                tooltip == "Liked tracks" ||
                tooltip == "Create new" ||
                tooltip == "Sign in"
                  ? `w-24`
                  : `w-auto`
              } top-8 left-6 mt-1 px-2 py-1 text-sm text-white bg-white/20 rounded backdrop-blur-[20px] shadow-lg z-10`}
            >
              {tooltip}
            </div>
          )}
        </>
        {dropdownClick && !userAvatar && (
          <DropdownIcon className={`${iconStyle}`} />
        )}
        {createNew && (
          <div className="absolute right-3 text-xs flex items-center">
            {userAgent.includes("win") ? (
              <>
                <img
                  src="/ctrl.svg"
                  alt="Control Key Icon"
                  className="mr-0.5"
                />
                <img src="/keyword-I.svg" alt="Keyword I Icon" />
              </>
            ) : (
              <>
                <img src="/cmd.svg" alt="Command Key Icon" className="mr-0.5" />
                <img src="/keyword-I.svg" alt="Keyword I Icon" />
              </>
            )}
          </div>
        )}
        {hoverImage && isHovered && (
          <img
            src={hoverImage}
            alt="Hover preview"
            className={hoverImageStyle}
          />
        )}
        {hoverVideo && isHovered && (
          <video
            src={hoverVideo}
            className={hoverVideoStyle}
            type="video/mp4"
            loop
            autoPlay
          />
        )}
        {hoverAudio && isHovered && <AudioBars />}
        {hoverAudio && isHovered && <audio autoPlay src={hoverAudio} />}
      </button>
      {(!userAvatar || pageWidth >= 768) &&
        dropdownVisible &&
        dropdownOptions && (
          <div className={dropdownStyle}>
            {dropdownOptions.map((option) => {
              const OptionIcon = iconComponents[option.icon];
              return (
                <div
                  id={option.id}
                  key={option.label}
                  onClick={() => handleDropdownClick(option)}
                  className={`px-2 py-2 cursor-pointer rounded-lg flex items-center gap-2 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] ${option.textStyle}`}
                >
                  {OptionIcon && <OptionIcon className="w-4 h-4" />}
                  {option.img && <img src={option.img} alt={option.label} />}
                  {option.label}
                </div>
              );
            })}
          </div>
        )}
      {showZapierChat && (
        <iframe
          src="https://interfaces.zapier.com/embed/chatbot/cm6914edy0092128fcfo820q7"
          height="480px"
          width="360px"
          allow="clipboard-write *"
          style={{
            borderRadius: "8px",
            position: "fixed",
            bottom: "72px",
            right: "20px",
          }}
        ></iframe>
      )}
    </div>
  );
}

function renderModalContent(modalContent, renderComponents) {
  return renderComponents([modalContent]);
  // const { name, props, children } = modalContent;
  // const ComponentToRender = componentMapper[name];

  // if (!ComponentToRender) {
  //   console.error(`Component "${name}" not found in componentMapper.`);
  //   return null;
  // }

  // return (
  //   <ComponentToRender {...props}>
  //     {children &&
  //       children.map((child, index) =>
  //         renderModalContent({
  //           name: child.name,
  //           props: { ...child.props, key: index },
  //           children: child.children,
  //         })
  //       )}
  //   </ComponentToRender>
  // );
}
