import { Xmark } from "@gravity-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../../../api/apiService";
import { useLocalState } from "../../../contexts/LocalStateProvider";
import { setSubscriptionModal } from "../../../store/reducers/subscriptionSlice";
import BasicCard from "./BasicCard";
import BusinessCard from "./BusinessCard";
import EnterpriseCard from "./EnterpriseCard";
import FilmAndBroadcastCard from "./FilmAndBroadcastCard";
import FreeCard from "./FreeCard";
import { useAuth } from "../../../contexts/AuthContext";
import { usePostHog } from "posthog-js/react";

const Subscription = () => {
  const { isLoggedIn } = useAuth();
  const { localState, updateLocalState } = useLocalState();
  const [isAnnual, setIsAnnual] = useState(false);
  const togglePlan = () => setIsAnnual((prev) => !prev);
  const dispatch = useDispatch();
  const [openSummary, setOpenSummary] = useState(false);
  const { openSubscriptionModal } = useSelector((state) => state.subscription);

  useEffect(() => {
    if (isLoggedIn) {
      fetchAdminSettings();
    }
  }, [openSubscriptionModal, isLoggedIn]);

  useEffect(() => {
    if (openSubscriptionModal) {
      setIsAnnual(
        localState?.userProfileData?.subscription_type?.includes("Annual")
          ? true
          : false
      );
    }
  }, [openSubscriptionModal, isLoggedIn]);

  const fetchAdminSettings = async () => {
    const response = await apiService.handleProtectedRoute(
      localState.isTeamMember ? "fetchTeamUserProfile" : "fetchUserProfile",
      {}
    );

    // console.log("Response from server:", response);

    updateLocalState("userProfileData", response);
  };

  const closeModal = () => {
    setIsAnnual(
      localState?.userProfileData?.subscription_type?.includes("Annual")
        ? true
        : false
    );
    dispatch(setSubscriptionModal(false));
  };
  return (
    <>
      {openSubscriptionModal && (
        <SubscriptionPlans
          togglePlan={() => togglePlan()}
          isAnnual={isAnnual}
          FreeCard={FreeCard}
          BasicCard={BasicCard}
          BusinessCard={BusinessCard}
          EnterpriseCard={EnterpriseCard}
          FilmAndBroadcastCard={FilmAndBroadcastCard}
          onClose={() => closeModal()}
          handleNext={() => setOpenSummary(true)}
          openSummary={openSummary}
        />
      )}
    </>
  );
};

const SubscriptionPlans = ({ togglePlan, isAnnual, onClose }) => {
  const posthog = usePostHog();

  const handleTogglePlan = () => {
    // Track the appropriate event based on the current state
    posthog.capture(
      !isAnnual ? "Click on Annual toggle" : "Click on Monthly toggle"
    );
    togglePlan();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-[#000000] bg-opacity-60 overflow-auto p-4">
      <div className="bg-[#1e1c1e] rounded-lg w-full max-w-[1152px] h-fit max-h-[90%] overflow-auto p-6 relative">
        <button
          className="absolute flex text-sm font-light leading-[14px] text-white/40 hover:text-white items-center top-2 right-2 h-8 gap-1 px-2 z-10 justify-center rounded-full [background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] hover-bg-white/10"
          onClick={onClose}
        >
          <div className="">Close</div>
          <div className="flex items-center">
            <Xmark className="w-4 h-4" />
          </div>
        </button>
        <div class="mb-3 flex items-center gap-1 justify-center rounded-lg">
          <img class="w-39 h-11 object-cover" src="/text_logo.png" />{" "}
        </div>
        {/* Modal Content */}
        <div className="flex flex-col gap-6 text-white">
          {/* Header Section */}
          <div className="flex flex-wrap items-center gap-4 justify-between">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-normal">
              Upgrade your game
            </h2>

            {/* Toggle Plan (Monthly/Annual) */}
            <div className="flex items-center gap-4">
              <span
                className={`text-base font-normal ${
                  !isAnnual ? "text-white" : "text-gray-500"
                }`}
              >
                Monthly
              </span>
              <div
                className={`flex w-8 h-5 p-0.5 items-center rounded-full cursor-pointer transition-all duration-300 ${
                  isAnnual ? "bg-[#d54dff]" : "bg-gray-700"
                }`}
                onClick={handleTogglePlan}
              >
                <div
                  className={`w-4 h-4 rounded-full bg-white transition-all duration-300 ${
                    isAnnual ? "translate-x-3 " : "translate-x-0"
                  }`}
                />
              </div>
              <span
                className={`text-base font-normal ${
                  isAnnual ? "text-white" : "text-gray-500"
                }`}
              >
                Annual
              </span>
            </div>
          </div>

          {/* Pricing Cards Section */}
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2">
            <FreeCard isAnnual={isAnnual} continue={true} />
            <BasicCard isAnnual={isAnnual} />
            <BusinessCard isAnnual={isAnnual} />
            <EnterpriseCard isAnnual={isAnnual} />
            <FilmAndBroadcastCard isAnnual={isAnnual} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscription;
