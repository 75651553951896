import React, { useState, useEffect } from "react";
import { useLocalState } from "../../contexts/LocalStateProvider";
import apiService from "../../api/apiService";
import SongList from "./SongList";
import ProjectLikedTracksList from "./ProjectLikedTracksList";
import { getCookie } from "../../utils/cookieUtils";
import SearchBar from "../generic_components/SearchBar";
import NoResultsFound from "./NoResultsFound";
import LoadingSongs from "./LoadingSongs";
import EmptyState from "./EmptyState";
import { ThumbsUp, Globe, Rectangles4 } from "@gravity-ui/icons";
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

const LikedTracks = () => {
  const [activeTab, setActiveTab] = useState("discover");
  const { localState, updateLocalState } = useLocalState();
  const [likedDiscoverTracks, setLikedDiscoverTracks] = useState([]);
  const [likedProjectTracks, setLikedProjectTracks] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDiscoverTracks, setFilteredDiscoverTracks] = useState([]);
  const [filteredProjectTracks, setFilteredProjectTracks] = useState([]);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const posthog = usePostHog();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add these useEffects
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    localState.isAudioPlaying
      ? setIsAudioPlaying(true)
      : setIsAudioPlaying(false);
  }, [localState.isAudioPlaying]);

  // Add useEffect for filtering
  useEffect(() => {
    if (activeTab === "discover") {
      const filtered = likedDiscoverTracks.filter((track) =>
        track.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDiscoverTracks(filtered);
    } else {
      const filtered = likedProjectTracks.filter(
        (track) =>
          track.name?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          track.projectName?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      setFilteredProjectTracks(filtered);
    }
  }, [searchTerm, likedDiscoverTracks, likedProjectTracks, activeTab]);

  useEffect(() => {
    if (!localState.likedDiscoverTracks) {
      fetchLikedDiscoverTracks();
    } else {
      setLikedDiscoverTracks(localState.likedDiscoverTracks);
      setLoading(false);
    }

    if (!localState.likedProjectTracks) {
      fetchLikedProjectTracks();
    } else {
      setLikedProjectTracks(localState.likedProjectTracks);
      setLoading(false);
    }
  }, [localState.likedDiscoverTracks, localState.likedProjectTracks]);

  const fetchLikedDiscoverTracks = async () => {
    try {
      setLoading(true);
      const userId = getCookie("user_id"); // Get the user ID from cookies

      const data = await apiService.handleProtectedRoute(
        "fetchLikedDiscoverTracks",
        { user_id: userId } // Pass the user ID as a parameter
      );

      const processedData = data.map((song) => {
        // Define the tags based on active filters
        const tags = [song.themes, song.mood, song.genre, song.instrument];

        return {
          id: song.id,
          name: song.title,
          duration: "0:30", // Placeholder, adjust based on actual audio length if needed
          audioSrc: `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/${song.file_name}`,
          image: `https://storage.googleapis.com/wubble-discover-files/${song.image_name}`,
          genre: song.genre,
          mood: song.mood,
          theme: song.themes,
          instrument: song.instrument,
          description: song.description,
          isLiked: song.is_liked,
          waveformData: song.waveform,
          displayTags: tags.join(" . "),
        };
      });

      setLikedDiscoverTracks(processedData);
      updateLocalState("likedDiscoverTracks", processedData);
    } catch (error) {
      console.error("Error fetching liked discover tracks:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLikedProjectTracks = async () => {
    try {
      setLoading(true);
      const data = await apiService.handleProtectedRoute(
        "fetchLikedProjectTracks",
        {}
      );

      const processedData = data.map((track) => ({
        ...track,
        audioSrc:
          track.is_streaming && track.request_id
            ? `${process.env.REACT_APP_SERVER_ENDPOINT}/api/audio-stream?requestId=${track.request_id}`
            : `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-audio/${track.response_id}`,
        imageSrc: track.signedUrlExists
          ? track.cover_image_name
          : !track.signedUrlExists && track.cover_image_name
          ? `/project-images/${track.cover_image_name}`
          : "/project-images/Album_Art_6.webp",
      }));

      setLikedProjectTracks(processedData);
      updateLocalState("likedProjectTracks", processedData);
    } catch (error) {
      console.error("Error fetching liked project tracks:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-full">
      <div className="hidden darker-filter md:block relative mx-4 mt-4 w-auto h-[9rem] overflow-hidden">
        {/* Pseudo-element for rounded border */}
        <div className="absolute inset-0 rounded-t-lg pointer-events-none [background:linear-gradient(135deg,#9AA51E_0%,#066C04_100%)] z-10"></div>

        {/* Folder SVG in the center with lower z-index */}
        <div className="absolute -bottom-[9rem] left-[50%] -translate-x-1/2 flex items-center justify-center z-10 opacity-70">
          <img
            src="/mdi_like.svg"
            alt="like Icon"
            className="w-[19rem] h-[24rem]"
          />
        </div>

        {/* right circles */}
        <div className="absolute -top-[23rem] -right-[26rem] w-[45rem] h-[45rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />
        <div className="absolute -bottom-[35rem] -right-[10rem] w-[45rem] h-[45rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move-reverse z-20" />

        {/* left circles */}
        <div className="absolute -bottom-80 -left-[40rem] w-[56rem] h-[56rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />
        <div className="absolute -bottom-[42rem] -left-[10em] w-[48rem] h-[48rem] bg-[#E181FF] opacity-[0.15] bg-blend-hard-light mix-blend-hard-light rounded-full animate-slow-move z-20" />

        {/* Bottom-left title */}
        <h1 className="absolute bottom-6 left-5 text-4xl font-normal text-white z-20">
          Liked Tracks
        </h1>
      </div>

      <div className="flex justify-between items-center">
        {!isSearchVisible && (
          <div className="flex gap-4 px-4">
            <button
              onClick={() => {
                posthog.capture("Discover Tab for Liked Tracks Clicked");
                setActiveTab("discover");
              }}
              className={`py-4 ${
                activeTab === "discover"
                  ? "text-white border-b border-b-[#fffff]"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
            >
              Discover
            </button>
            <button
              onClick={() => {
                posthog.capture("Project Tab for Liked Tracks Clicked");
                setActiveTab("projects");
              }}
              className={`py-4 ${
                activeTab === "projects"
                  ? "text-white border-b border-b-[#fffff]"
                  : "text-[#878188] hover:text-white duration-200"
              }`}
            >
              Projects
            </button>
          </div>
        )}

        <div
          className={`md:flex md:w-auto px-4 py-2.5 ${
            isSearchVisible && "w-full"
          }`}
        >
          <SearchBar
            placeholder="Search by song name, project"
            className="bg-transparent md:w-64 text-sm font-extralight placeholder-[#878188] h-9 rounded-full border border-solid border-[#878188] focus:outline-none appearance-none focus:ring-0"
            value={searchTerm}
            onSearch={setSearchTerm}
            onClear={() => setSearchTerm("")}
            onVisibilityChange={setIsSearchVisible}
            fromDiscover={false}
          />
        </div>
      </div>

      <div className="w-full border-t border-t-[#2C2A2C]"></div>

      {loading ? (
        <LoadingSongs itemCount={3} />
      ) : activeTab === "discover" ? (
        filteredDiscoverTracks.length > 0 ? (
          <div
            className={`max-h-[calc(100vh-${
              isAudioPlaying && windowWidth >= 768
                ? "320px"
                : windowWidth < 768 && isAudioPlaying
                ? "265px"
                : windowWidth < 768 && !isAudioPlaying
                ? "205px"
                : !isAudioPlaying && "255px"
            })] min-h-[300px] overflow-y-auto scrollable-content`}
          >
            <SongList songs={filteredDiscoverTracks} />
          </div>
        ) : localState.likedDiscoverTracks?.length > 0 && !loading ? (
          <NoResultsFound onClearFilters={() => setSearchTerm("")} />
        ) : (
          <EmptyState
            icon={<ThumbsUp className="w-10 h-10" />}
            title="No Liked Tracks yet!"
            description="Explore the Discover page and like tracks to see them here."
            buttonText="Take me to Discover"
            onButtonClick={() => navigate("/discover")}
            goToIcon={<Globe className="w-4 h-4" />}
          />
        )
      ) : filteredProjectTracks.length > 0 ? (
        <div
          className={`max-h-[calc(100vh-${
            isAudioPlaying && windowWidth >= 768
              ? "320px"
              : windowWidth < 768 && isAudioPlaying
              ? "265px"
              : windowWidth < 768 && !isAudioPlaying
              ? "205px"
              : !isAudioPlaying && "255px"
          })] min-h-[300px] overflow-y-auto scrollable-content`}
        >
          <ProjectLikedTracksList tracks={filteredProjectTracks} />
        </div>
      ) : localState.likedProjectTracks?.length > 0 && !loading ? (
        <NoResultsFound onClearFilters={() => setSearchTerm("")} />
      ) : (
        <EmptyState
          icon={<ThumbsUp className="w-10 h-10" />}
          title="No Liked Tracks yet!"
          description="Like tracks from your projects to see them here."
          buttonText="Take me to Projects"
          onButtonClick={() => navigate("/projects")}
          goToIcon={<Rectangles4 className="w-4 h-4" />}
        />
      )}
    </div>
  );
};

export default LikedTracks;
