import {
  Check,
  ArrowRight,
  ChevronRight,
  PersonPlus,
  TrashBin,
} from "@gravity-ui/icons";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { getCookie } from "../../utils/cookieUtils";
import apiService from "../../api/apiService";
// Replace with your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
import { useNavigate } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

const getPlans = (currentPeriod, tier) => {
  const plans = {
    Monthly: {
      Basic: {
        tier: "Basic",
        price: "$10",
        period: "Monthly",
        showSavings: true,
        features: [
          "30 minutes of royalty-free music",
          "Unlimited downloads",
          "Access to text, image, video & audio to music capabilities",
          "For social media usage",
          "Music editing & extension",
          "Song templates",
        ],
        additionalInfo: "$30/month for each additional member",
      },
      Business: {
        tier: "Business",
        price: "$30",
        period: "Monthly",
        showSavings: true,
        features: [
          "120 minutes of royalty-free music",
          "Unlimited downloads",
          "Access to text, image, video & audio to music capabilities",
          "For commercial usage",
          "Music editing & extension",
          "Song templates",
        ],
        additionalInfo: "$30/month for each additional member",
      },
    },
    Annual: {
      Basic: {
        tier: "Basic",
        price: "$110",
        period: "Annual",
        showSavings: true,
        features: [
          "360 minutes of royalty-free music",
          "Unlimited downloads",
          "Access to image, video & audio to music capabilities",
          "For social media usage",
          "Music editing & extension",
          "Song templates",
        ],
        additionalInfo: "$110/month for each additional member",
      },
      Business: {
        tier: "Business",
        price: "$330",
        period: "Annual",
        showSavings: true,
        features: [
          "1440 minutes of royalty-free music",
          "Unlimited downloads",
          "Access to image, video & audio to music capabilities",
          "For commercial usage",
          "Music editing & extension",
          "Song templates",
        ],
        additionalInfo: "$330/month for each additional member",
      },
    },
  };
  return plans[currentPeriod][tier];
};

const TeamPurchaseSummary = ({
  teamMembersInvited,
  onUpdateTeamMembers,
  isCreateTeam = false,
}) => {
  const posthog = usePostHog();
  const [teamMembers, setTeamMembers] = useState(teamMembersInvited);
  const [adminEmail, setAdminEmail] = useState("");
  const { invitedEmails } = teamMembers;
  const { localState } = useLocalState();
  const { team_id } = localState?.userProfileData;
  const { planDetails, selectedPlan, teamId } = useSelector(
    (state) => state.subscription
  );
  // console.log("selectedPlan++++++++++++++", selectedPlan.split("-")[0]);
  const plans = getPlans(
    selectedPlan.split("-")[0],
    selectedPlan.split("-")[1]
  );
  const handleRemoveMember = (email) => {
    const updatedInvitedEmails = invitedEmails.filter(
      (invitedEmail) => invitedEmail !== email
    );
    // Update the teamMembers prop with the new invitedEmails array
    const updatedTeamMembers = {
      ...teamMembers,
      invitedEmails: updatedInvitedEmails,
    };
    setTeamMembers(updatedTeamMembers);
  };

  const basePrice = planDetails.amount; // Base price per team member
  const teamSize = 1;
  const totalPrice = basePrice * teamSize;
  const navigate = useNavigate();

  const checkCurrentUserEmail = async () => {
    try {
      const response = await apiService.handleProtectedRoute(
        "fetchUserEmail",
        {}
      );
      setAdminEmail(response.email);
    } catch (error) {
      console.error("Error fetching current user email:", error);
      return null;
    }
  };

  const calculateTotal = () => {
    const memberCount = teamMembers.invitedEmails.length + 1; // +1 for admin
    const basePrice = planDetails.amount;
    return basePrice * memberCount;
  };

  useEffect(() => {
    checkCurrentUserEmail();
  }, []);

  useEffect(() => {
    if (teamMembersInvited) {
      setTeamMembers(teamMembersInvited);
    }
  }, [teamMembersInvited]);

  // const handleSubscribe = async () => {
  //   // Get Stripe.js instance
  //   const stripe = await stripePromise;
  //   const priceId = planDetails.price; // Basic Plan Price ID
  //   const payment_type = `team_${selectedPlan
  //     .split("-")[1]
  //     ?.toLowerCase()}_${selectedPlan.split("-")[0].toLowerCase()}`;

  //   // Call your backend to create the Checkout Session
  //   const response = await fetch(
  //     `${process.env.REACT_APP_SERVER_ENDPOINT}/api/create-checkout-session`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${getCookie("token")}`,
  //       },
  //       body: JSON.stringify({
  //         priceId,
  //         payment_type,
  //         upgradeType: "upgrade",
  //         teamId: team_id ?? teamId,
  //         invitedEmails: teamMembers.invitedEmails,
  //       }),
  //     }
  //   );

  //   const { sessionId } = await response.json();

  //   // Redirect to Stripe Checkout
  //   const { error } = await stripe.redirectToCheckout({
  //     sessionId,
  //   });

  //   if (error) {
  //     console.error("Error redirecting to Stripe Checkout:", error);
  //     setToastMessage(
  //       error.response?.data?.error || "Error redirecting to Stripe Checkout:"
  //     );
  //     setToastType("error");
  //   }
  // };

  const handleSubscribe = async () => {
    posthog.capture("Click on Subscribe button");

    const stripe = await stripePromise;
    const priceId = planDetails.price;
    const payment_type = `team_${selectedPlan
      .split("-")[1]
      ?.toLowerCase()}_${selectedPlan.split("-")[0].toLowerCase()}`;
    const memberCount = teamMembers.invitedEmails.length + 1;

    const checkoutResponse = await apiService.CreateTeamSubmission({
      teamId: team_id ?? teamId,
      priceId,
      memberCount,
      payment_type,
      upgradeType: isCreateTeam ? "purchase" : "upgrade",
      invitedEmails: teamMembers.invitedEmails,
    });

    await stripe.redirectToCheckout({
      sessionId: checkoutResponse.sessionId,
    });
  };

  return (
    <>
      <div className="fixed inset-0 z-50 flex justify-center bg-[#1D1B1D] bg-opacity-100 overflow-auto p-2">
        <div className="bg-[#100F10] rounded-lg w-full max-w-3xl p-4 relative border border-[#2c2a2c]">
          {/* Close Button */}

          <div className="flex justify-between items-center pb-3 border-b border-b-[#2C2A2C]">
            <div
              className="relative h-9 flex items-center gap-2 rounded-2.5"
              id="sidebar-logo"
              onClick={() => {
                navigate(0);
                navigate("/");
              }}
            >
              <img
                className="w-10 h-10 object-cover rounded-full cursor-pointer"
                src="/logo@2x.png"
                alt="Logo"
                id="sidebar-logo-image"
              />
              <img
                className="w-[5.9rem] h-8 object-cover z-10 hover:opacity-0 transition duration-500 cursor-pointer"
                src="/text_only_white.svg"
                alt="White Text"
                id="sidebar-logo-text-white"
              />
              <img
                className="absolute left-12 w-[5.9rem] h-8 object-cover cursor-pointer"
                src="/text_only_gradient.svg"
                alt="Gradient Text"
                id="sidebar-logo-text-gradient"
              />
            </div>
            <button
              onClick={() => handleSubscribe()}
              className={`flex items-center text-sm font-normal h-9 px-3 rounded-full transition-all duration-500 ${"bg-gradient-to-b from-[#fc10f2] to-[#3078e4] text-white hover:[background:linear-gradient(135deg,#e509e4,#256bb8)]"}`}
            >
              Subscribe <ArrowRight className="ml-2" />
            </button>
          </div>

          {/* Modal Content */}
          <div className="flex flex-col pt-6 pb-2 gap-6 w-full h-full max-h-[calc(100vh-100px)]">
            <div className="flex flex-wrap items-center gap-4 justify-between">
              <h2 className="text-2xl text-white md:text-3xl lg:text-4xl font-medium">
                Summary of your purchase
              </h2>

              {/* Toggle Plan (Monthly/Annual) */}
              {/* <div className="flex items-center gap-4">
                <span
                  className={`text-base font-medium ${
                    selectedPlan.split("-")[0].toLowerCase() === "annual"
                      ? "text-white"
                      : "text-gray-500"
                  }`}
                >
                  Monthly
                </span>
                <div
                  className="flex w-8 h-5 p-0.5 items-center bg-gray-700 rounded-full cursor-pointer transition-all duration-300"
                  // onClick={togglePlan}
                >
                  <div
                    className={`w-4 h-4 rounded-full bg-white transition-all duration-300 ${
                      selectedPlan.split("-")[0].toLowerCase() === "annual"
                        ? "translate-x-3"
                        : "translate-x-0"
                    }`}
                  />
                </div>
                <span
                  className={`text-base font-medium ${
                    selectedPlan.split("-")[0].toLowerCase() === "annual"
                      ? "text-white"
                      : "text-gray-500"
                  }`}
                >
                  Annual
                </span>
              </div> */}
            </div>

            <div className="flex flex-col sm:flex-row gap-2 w-full scrollable-content md:h-full overflow-y-hidden">
              <div className="flex flex-col bg-transparent border border-white/10 rounded-lg md:grow w-full">
                <div className="flex justify-between items-center p-2 pl-3 border-b border-b-[color:var(--Color-Opacity-White-10,rgba(255,255,255,0.10))]">
                  <span className="text-white text-base font-normal">
                    {teamMembers.invitedEmails.length + 1} Team Members
                  </span>
                  <button
                    onClick={() => {
                      posthog.capture("Click on Add more button");
                      onUpdateTeamMembers(teamMembers);
                    }}
                    className="flex items-center hover:text-white duration-200 gap-1 px-2.5 text-[#878188] h-8 bg-white/5 hover:bg-white/10 rounded-full"
                  >
                    <div className="w-4 h-4 bg-cover">
                      <PersonPlus />
                    </div>
                    <span className="text-sm">Add member</span>
                  </button>
                </div>
                <div className="flex gap-2 h-14 px-2 w-full items-center border-b border-b-[color:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]">
                  <span className="w-36 text-[#878188] text-sm text-left font-extralight">
                    Admin
                  </span>
                  <span className="w-full text-[#878188] text-sm text-left font-extralight">
                    {adminEmail}
                  </span>
                </div>
                {teamMembers.invitedEmails.map((email, index) => (
                  <div className="flex gap-2 h-14 px-2 w-full items-center border-b border-b-[color:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))]">
                    <span className="w-40 text-[#878188] text-sm  text-left font-extralight">
                      Invited
                    </span>
                    <span className="w-full text-[#878188] text-sm text-left font-extralight">
                      {email}
                    </span>
                    <div
                      onClick={() => {
                        posthog.capture(
                          "Click on delete icon for invited user"
                        );
                        handleRemoveMember(email);
                      }}
                      className="w-4 mr-2 flex justify-end cursor-pointer items-center hover:text-white duration-200 rounded-full text-[#878188]"
                    >
                      <TrashBin />
                    </div>
                  </div>
                ))}
              </div>

              <div
                className={`flex flex-col w-full border border-white/10 rounded-lg md:grow
                ${
                  plans.tier === "Basic"
                    ? "bg-transparent"
                    : "bg-gradient-to-br from-[#fc10f21a] to-[#3078e41a]"
                }`}
              >
                <div className="flex items-start p-3">
                  <span className="text-white text-base font-normal">
                    {plans.tier}
                  </span>
                </div>
                <div className="flex items-start p-3 pt-0 border-b border-white/10">
                  <div className="flex flex-col items-start">
                    <span className="text-white text-2xl font-normal">
                      ${totalPrice}
                    </span>
                    <span className="text-[#C5C3C6] text-xs font-normal">
                      {plans.period}
                    </span>
                  </div>
                </div>
                <ul className="space-y-2 text-white text-sm p-3 md:grow">
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-4 h-4 rounded-full">
                      <Check className="w-4 h-4" />
                    </div>
                    <span className="text-white text-sm font-extralight">
                      {plans.features[0]}
                    </span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-4 h-4 rounded-full">
                      <Check className="w-4 h-4" />
                    </div>
                    <span className="text-white text-sm font-extralight">
                      {plans.features[1]}
                    </span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-4 h-4 rounded-full">
                      <Check className="w-4 h-4" />
                    </div>
                    <span className="text-white text-sm font-extralight">
                      {plans.features[2]}
                    </span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-4 h-4 rounded-full">
                      <Check className="w-4 h-4" />
                    </div>
                    <span className="text-white text-sm font-extralight">
                      {plans.features[3]}
                    </span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-4 h-4 rounded-full">
                      <Check className="w-4 h-4" />
                    </div>
                    <span className="text-white text-sm font-extralight">
                      {plans.features[4]}
                    </span>
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-4 h-4 rounded-full">
                      <Check className="w-4 h-4" />
                    </div>
                    <span className="text-white text-sm font-extralight">
                      {plans.features[5]}
                    </span>
                  </li>
                  {/* <li className="flex items-center gap-2">
                    <div className="flex items-center w-3 h-3 bg-[#ba18ec] rounded-full">
                      <Check className="w-11/12 h-11/12 bg-cover" />
                    </div>
                    All modalities
                  </li>
                  <li className="flex items-center gap-2">
                    <div className="flex items-center w-3 h-3 bg-[#ba18ec] rounded-full">
                      <Check className="w-11/12 h-11/12 bg-cover" />
                    </div>
                    For commercial use
                  </li> */}
                </ul>
                <div className="p-3 border-t border-white/10 items-center gap-2 flex">
                  <div className="text-[#c5c2c6] text-sm font-extralight w-full">
                    Total Amount
                  </div>
                  <div className="text-white text-lg font-normal">
                    ${calculateTotal()}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-2 md:flex sm:place-items-center justify-center items-center">
              <span className="text-white text-sm font-extralight">
                Thinking bigger? Wubble Enterprise is here for your team
              </span>
              <button className="flex flex-row gap-0.5 hover:text-[#eab6f9] duration-200 items-center text-sm text-[#d54dff] rounded-md">
                Talk to Us <ChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamPurchaseSummary;
