import React, { useState, useEffect, useRef } from "react";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { Range, getTrackBackground } from "react-range";
import Modal from "../modal/Modal"; // Import the Modal component
import CustomInput from "../custom_components/CustomInput"; // Import the CustomInput component
import apiService from "../../api/apiService";
import {
  PlayFill,
  PauseFill,
  ArrowShapeDownToLine,
  MusicNote,
  Xmark,
  Ellipsis,
  VolumeLow,
  VolumeOff,
  VolumeXmark,
  Volume,
  ChevronDown,
} from "@gravity-ui/icons";
import {
  FaPlay,
  FaPause,
  FaStepBackward,
  FaStepForward,
  FaVolumeUp,
  FaVolumeMute,
  FaEllipsisH,
} from "react-icons/fa";
import Toast from "../generic_components/Toast";
import { useLocation } from "react-router-dom";
import AudioProcessor from "../custom_components/AudioProcessor";
import { usePostHog } from "posthog-js/react";
import { useLocalState } from "../../contexts/LocalStateProvider";

const AudioPlayer = () => {
  const {
    audioSrc,
    title,
    description,
    isPlaying,
    pauseAudio,
    resumeAudio,
    stopAudio,
    currentTime,
    duration,
    volume,
    seekAudio,
    changeVolume,
    imageSrc,
    isBuffering,
    streamingSrc,
    aiResponseId,
    discoverId,
  } = useAudioPlayer();
  const posthog = usePostHog();
  const [showVolumeSlider, setShowVolumeSlider] = useState(true);
  const [showVolume, setShowVolume] = useState(true);
  const [showOptionsDropdown, setShowOptionsDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [toastMessage, setToastMessage] = useState(null);
  const [isVolumeHovered, setIsVolumeHovered] = useState(false);
  const [isPlaybackHovered, setIsPlaybackHovered] = useState(false); // State to track hover on playback progress bar
  const [isPlaybackDragging, setIsPlaybackDragging] = useState(false);
  const [isVolumeDragging, setIsVolumeDragging] = useState(false);
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [selectedSong, setSelectedSong] = useState(null);
  const { localState } = useLocalState();

  // Show the spinner only if the current audio is the one streaming
  const isCurrentAudioBuffering = isBuffering && streamingSrc === audioSrc;
  const [isLoadingAudio, setIsLoadingAudio] = useState(false);
  const [audioFile, setAudioFile] = useState(null);
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePlayPause = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      resumeAudio();
    }
  };

  const handleSeek = (values) => {
    if (isBuffering) return;

    if (duration) {
      const newTime = (values[0] / 100) * duration;
      // console.log("New time:", newTime, audioSrc);

      seekAudio(newTime);
    }
  };

  const handleVolumeChange = (values) => {
    changeVolume(values[0] / 100);
  };

  const mute = () => {
    changeVolume(showVolume ? 0 : 1);
    setShowVolume((prev) => !prev);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleOpenModal = async () => {
    if (!discoverId) {
      setToastMessage("Please select a song first.");
      return;
    }

    setIsLoadingAudio(true);
    setIsModalOpen(true);

    const taskName = "fetchDiscoverAudioFile";
    const requestPayload = { discoverId };

    try {
      const response = await apiService.sendRequest(taskName, requestPayload);
      const { audioFile } = response;

      if (audioFile.data) {
        const blob = new Blob([new Uint8Array(audioFile.data)], {
          type: "audio/wav",
        });

        const file = new File([blob], `${title}.wav`, { type: blob.type });

        setAudioFile(file);
        setIsLoadingAudio(false);
      } else {
      }
    } catch (error) {
      console.error("Error fetching the audio file:", error);
    }
  };

  const handleDownload = () => {
    setIsDownloadModalOpen(true);
  };

  const handleCustomize = (e) => {
    e.stopPropagation();
    posthog.capture("Click to Customize Discover Audio", {
      song: title,
    });

    const fileName = audioSrc?.split("/")?.pop();

    const fileData = {
      name: title,
      type: "audio/wav",
      url: `gs://discover_songs/${fileName}`,
    };

    setSelectedSong({
      audioFile: fileData,
      image: localState.audioImgSrc || imageSrc,
      mood: description,
    });

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowOptionsDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Determine the volume icon to display
  const getVolumeIcon = () => {
    if ((volume > 0) & (volume <= 0.3)) {
      return <VolumeOff className="w-5 h-5 text-white" />;
    }
    if (volume > 0.3 && volume < 0.8) {
      return <VolumeLow className="w-5 h-5 text-white" />;
    }
    if (volume === 0) {
      return <VolumeXmark className="w-5 h-5 text-white" />;
    }
    return <Volume className="w-5 h-5 text-white" />; // Full volume
  };

  if (!audioSrc) {
    return null;
  }

  // Expanded audio player modal view
  const expandedPlayerModal = (
    <div className="fixed inset-0 [background:linear-gradient(180deg,#2C2A2C_0%,#100F10_100%)] p-4 flex flex-col items-center gap-6 z-20 overflow-hidden">
      <button
        id="close-expanded-player-button"
        onClick={() => setIsExpanded(false)}
        className="self-start text-[#878188] hover:text-white p-2 bg-[rgba(255,255,255,0.05)] rounded-full "
      >
        <ChevronDown className="w-4 h-4 text-white" />
      </button>

      <div className="w-full max-w-lg mx-auto">
        <img
          src={localState.audioImgSrc || imageSrc}
          alt="Cover"
          className="w-full object-cover rounded-md mb-6"
        />

        <div className="text-center mb-6">
          <span className="font-normal text-2xl text-white w-full">
            {title}
          </span>
          <p className="w-full text-base font-light text-[#c5c3c6]">
            {description}
          </p>
        </div>

        <div className="w-full flex flex-col items-center mb-6">
          <div className="w-full flex items-center mb-1">
            <Range
              id="audio-progress-bar"
              step={1}
              min={0}
              max={100}
              values={[
                duration && isFinite(duration)
                  ? (currentTime / duration) * 100
                  : 0,
              ]}
              onChange={(values) => seekAudio((values[0] / 100) * duration)}
              renderTrack={({ props, children }) => {
                const progressBackground = `linear-gradient(135deg, #FC10F2 0%, #3078E4 ${
                  (currentTime / duration) * 100
                }%, #404040 ${(currentTime / duration) * 100}%)`;

                return (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "8px",
                      width: "100%",
                      borderRadius: "100px",
                      background: progressBackground,
                    }}
                  >
                    {children}
                  </div>
                );
              }}
              renderThumb={({ props, isDragged }) => {
                return (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: isDragged ? "20px" : "20px",
                      width: isDragged ? "20px" : "20px",
                      borderRadius: "100%",
                      backgroundColor: "#FFF",
                    }}
                  />
                );
              }}
              className="mx-2 grow h-1 bg-white rounded-[100px]"
            />
          </div>
          <div className="flex items-center w-full justify-between pt-2">
            <span className="text-sm font-extralight text-white">
              {formatTime(currentTime)}
            </span>
            <span className="text-sm font-extralight text-white">
              {duration && isFinite(duration) ? formatTime(duration) : "Live"}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-center gap-6 mb-6">
          <button
            id="rewind-button"
            onClick={() => seekAudio(currentTime - 10)}
            disabled={isBuffering}
            className="w-11 h-11 flex justify-center items-center hover:bg-white/15 disabled:bg-none rounded-full"
          >
            <img
              src="Skip-Backward-2.svg"
              className="w-6 h-6 text-white disabled:opacity-50"
            />
          </button>
          <button
            id="play-pause-button"
            onClick={handlePlayPause}
            className="w-14 h-14 flex justify-center items-center bg-white hover:bg-white/90 rounded-full"
          >
            {isPlaying ? (
              <PauseFill className="w-6 h-6 text-[#100f10]" />
            ) : (
              <PlayFill className="w-6 h-6 text-[#100f10]" />
            )}
          </button>
          <button
            id="fast-forward-button"
            onClick={() => seekAudio(currentTime + 10)}
            disabled={isBuffering}
            className="w-11 h-11 flex justify-center items-center hover:bg-white/15 disabled:bg-none rounded-full"
          >
            <img
              src="Skip-Forward-2.svg"
              className="w-6 h-6 text-white disabled:opacity-50"
            />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col w-full h-full">
      {isExpanded ? (
        expandedPlayerModal
      ) : (
        <div className="relative w-full bg-[#1d1b1d] pt-2 md:pb-1 pb-2 px-4 flex items-center">
          <div className="flex items-center gap-0 grow justify-between content-center">
            <div
              onClick={() => pageWidth < 768 && setIsExpanded(true)}
              className="flex items-center gap-3 grow pr-3 max-w-72"
            >
              <img
                src={localState.audioImgSrc || imageSrc}
                alt="Cover"
                className="w-12 h-12 object-cover rounded-md"
              />
              <div className="flex flex-col justify-center gap-0 grow">
                <span className="font-normal text-base text-white truncate w-full max-w-40">
                  {title}
                </span>
                <span className="text-xs font-extralight text-[#c5c3c6] truncate w-full max-w-40">
                  {description}
                </span>
              </div>
            </div>
            <div className="hidden md:flex flex-col items-center gap-0.5 grow max-w-[35rem]">
              <div className="flex items-center gap-2">
                <button
                  id="rewind-button"
                  onClick={() => seekAudio(currentTime - 10)}
                  disabled={isBuffering}
                  className={`w-8 h-8 flex justify-center items-center ${
                    !isBuffering && `hover:bg-white/10`
                  } rounded-full disable:opacity/50 disabled:cursor-not-allowed`}
                >
                  <img
                    src="Skip-Backward-2.svg"
                    className={`w-5 h-5 text-white ${
                      isBuffering && `opacity-40`
                    }`}
                  />
                </button>
                <button
                  id="play-pause-button"
                  onClick={handlePlayPause}
                  className="w-10 h-10 flex justify-center items-center bg-white hover:bg-white/90 rounded-full"
                >
                  {isPlaying ? (
                    <PauseFill className="w-4 h-4 text-[#100f10]" />
                  ) : (
                    <PlayFill className="w-4 h-4 text-[#100f10]" />
                  )}
                </button>
                <button
                  id="fast-forward-button"
                  onClick={() => seekAudio(currentTime + 10)}
                  disabled={isBuffering}
                  className={`w-8 h-8 flex justify-center items-center ${
                    !isBuffering && `hover:bg-white/10`
                  } rounded-full disable:opacity/50 disabled:cursor-not-allowed `}
                >
                  <img
                    src="Skip-Forward-2.svg"
                    className={`w-5 h-5 text-white ${
                      isBuffering && `opacity-40`
                    }`}
                  />
                </button>
              </div>
              <div className="hidden md:flex items-center gap-2 w-full relative">
                {isCurrentAudioBuffering && (
                  <div className="absolute left-[-24px] top-0 bottom-0 flex items-center justify-center">
                    <div className="inline-block h-3.5 w-3.5 animate-spin rounded-full border-2 border-solid border-r-transparent border-[#bc1bb4] align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                  </div>
                )}
                <span className="text-xs font-extralight text-white">
                  {formatTime(currentTime)}
                </span>
                <div
                  className="w-full"
                  onMouseEnter={() => setIsPlaybackHovered(true)}
                  onMouseLeave={() => setIsPlaybackHovered(false)}
                >
                  <Range
                    id="audio-progress-bar"
                    step={1}
                    min={0}
                    max={100}
                    values={[
                      duration && isFinite(duration)
                        ? (currentTime / duration) * 100
                        : 0,
                    ]}
                    onChange={handleSeek}
                    renderTrack={({ props, children }) => {
                      const progressBackground =
                        isPlaybackHovered || isPlaybackDragging
                          ? `linear-gradient(135deg, #FC10F2 0%, #3078E4 ${
                              (currentTime / duration) * 100
                            }%, #404040 ${(currentTime / duration) * 100}%)`
                          : getTrackBackground({
                              values: [
                                duration && isFinite(duration)
                                  ? (currentTime / duration) * 100
                                  : 0,
                              ],
                              colors: ["white", "#404040"], // Default is white for the progress and gray for the remaining part
                              min: 0,
                              max: 100,
                            });

                      return (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height: "4px",
                            width: "100%",
                            borderRadius: "100px",
                            background: progressBackground,
                          }}
                        >
                          {children}
                        </div>
                      );
                    }}
                    renderThumb={({ props, isDragged }) => {
                      if (isDragged) setIsPlaybackDragging(true);
                      else setIsPlaybackDragging(false);

                      return (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            height:
                              isDragged || isPlaybackHovered ? "12px" : "4px",
                            width:
                              isDragged || isPlaybackHovered ? "12px" : "4px",
                            borderRadius: "100%",
                            backgroundColor: "#FFF",
                          }}
                        />
                      );
                    }}
                    className="mx-2 grow h-1 bg-white rounded-[100px]"
                  />
                </div>
                <span className="text-xs font-extralight text-white">
                  {duration && isFinite(duration)
                    ? formatTime(duration)
                    : "Live"}
                </span>
              </div>
            </div>
            <div className="flex justify-end items-center gap-5 grow pl-3 md:pr-16 md:max-w-72">
              <div className="hidden md:flex items-center py-3 gap-2">
                <button
                  id="mute-button"
                  onClick={() => mute()}
                  className="w-4 h-4 flex justify-center items-center rounded-full"
                >
                  {getVolumeIcon()}
                </button>
                {showVolumeSlider && (
                  <div
                    onMouseEnter={() => setIsVolumeHovered(true)}
                    onMouseLeave={() => setIsVolumeHovered(false)}
                  >
                    <Range
                      id="volume-slider"
                      step={1}
                      min={0}
                      max={100}
                      values={[volume * 100]}
                      onChange={handleVolumeChange}
                      renderTrack={({ props, children }) => {
                        const volumeBackground =
                          isVolumeHovered || isVolumeDragging
                            ? `linear-gradient(135deg, #FC10F2 0%, #3078E4 ${
                                volume * 100
                              }%, #404040 ${volume * 100}%)`
                            : getTrackBackground({
                                values: [volume * 100],
                                colors: ["white", "#404040"], // Default white progress and gray remainder
                                min: 0,
                                max: 100,
                              });

                        return (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "5px",
                              width: "4rem",
                              borderRadius: "3px",
                              background: volumeBackground,
                            }}
                          >
                            {children}
                          </div>
                        );
                      }}
                      renderThumb={({ props, isDragged }) => {
                        if (isDragged) setIsVolumeDragging(true);
                        else setIsVolumeDragging(false);

                        return (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height:
                                isDragged || isVolumeHovered ? "12px" : "5px",
                              width:
                                isDragged || isVolumeHovered ? "12px" : "5px",
                              borderRadius: "100%",
                              backgroundColor: "#FFF",
                            }}
                          />
                        );
                      }}
                      className="w-16 mx-2 h-1"
                    />
                  </div>
                )}
              </div>
              <div
                ref={dropdownRef}
                className="flex flex-row md:gap-4 gap-6 relative"
              >
                <button
                  id="play-pause-button"
                  onClick={handlePlayPause}
                  className="md:hidden flex justify-center items-center"
                >
                  {isPlaying ? (
                    <PauseFill className="w-4 h-4 text-[#ffffff]" />
                  ) : (
                    <PlayFill className="w-4 h-4 text-[#ffffff]" />
                  )}
                </button>
                <div className="md:h-8.5 md:w-8.5 md:p-[1.7px] md:hover:bg-gradient-to-r from-[#fc10f2] to-[#3078e4] transition duration-500 md:rounded-full">
                  <button
                    id="audio-player-dropdown-button"
                    onClick={() => setShowOptionsDropdown(!showOptionsDropdown)}
                    className="md:w-7 md:h-7 flex justify-center items-center md:bg-[#2c2a2c] md:rounded-full"
                  >
                    <Ellipsis className="w-4 h-4 md:text-white text-[#878188]" />
                  </button>
                </div>
                {showOptionsDropdown && (
                  <div
                    id="audio-options-dropdown"
                    className="absolute right-0 w-[9rem] text-center text-[#878188] text-sm bottom-9 mt-2 font-normal bg-[#100f10] rounded-lg border border-solid border-[#2c2a2c] shadow-lg z-20"
                  >
                    {!discoverId && (
                      <div
                        id="audio-download-button"
                        className="flex items-center transition duration-200 m-1 py-2 px-2 cursor-pointer rounded-md hover:bg-zinc-900"
                        onClick={handleDownload}
                      >
                        <ArrowShapeDownToLine className="mr-2" />
                        Download
                      </div>
                    )}
                    {discoverId &&
                      localState.userProfileData?.remaining_credits > 0 && (
                        <div
                          className="flex items-center m-1 py-2 px-2 transition duration-200 cursor-pointer rounded-md hover:bg-zinc-900"
                          onClick={handleCustomize}
                        >
                          <MusicNote className="mr-2" />
                          Customize
                        </div>
                      )}
                    <div
                      id="audio-stop-button"
                      className="flex items-center m-1 py-2 px-2 transition duration-200 cursor-pointer rounded-md hover:bg-zinc-900"
                      onClick={stopAudio}
                    >
                      <Xmark className="mr-2" />
                      Close
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {toastMessage && (
            <Toast
              type="error"
              message={toastMessage}
              onClose={() => setToastMessage(null)}
            />
          )}

          {/* {isModalOpen && (
        <Modal closeModal={handleCloseModal} showCloseButton={false}>
          <CustomInput audioFile={audioSrc} className="w-[640px]" />
        </Modal>
      )} */}

          {isDownloadModalOpen && (
            <AudioProcessor
              currentResponse={{
                ai_response_id: aiResponseId,
                audio: {
                  name: title,
                  duration:
                    typeof duration === "string"
                      ? duration
                      : formatTime(duration) || "0:00",
                },
                avatar: localState.audioImgSrc || imageSrc,
                musicDescription: description,
              }}
              onClose={() => setIsDownloadModalOpen(false)}
            />
          )}

          {isModalOpen && (
            <Modal
              closeModal={handleCloseModal}
              showCloseButton={false}
              easyClose={true}
            >
              <CustomInput
                audioFile={selectedSong.audioFile}
                imageSrc={selectedSong.image}
                mood={description}
                fromDiscoverPage={true}
                className="w-full p-4 md:w-[40rem]"
                requestData={{ createProject: true }}
                chatPage={false}
              />
            </Modal>
          )}
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;
