import React, { useState } from "react";
import lamejs from "@breezystack/lamejs";
import apiService from "../../api/apiService";
import { Clock, Xmark } from "@gravity-ui/icons";
import Toast from "../generic_components/Toast";
import { usePostHog } from "posthog-js/react";

const AudioProcessor = ({ onClose, currentResponse }) => {
  const [fileType, setFileType] = useState("wav");
  const [isProcessing, setIsProcessing] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("info");
  const posthog = usePostHog();

  // const fetchAndProcessAudio = async () => {
  //   posthog.capture("Click to Download Audio");
  //   setIsProcessing(true);
  //   try {
  //     // Fetch audio data from backend
  //     const response = await apiService.sendRequest(
  //       "fetchCompleteAIResponseAudio",
  //       {
  //         ai_response_id: currentResponse.ai_response_id,
  //       }
  //     );

  //     if (!response.success) {
  //       posthog.capture("Failed to Download audio file");
  //       response?.message
  //         ? setToastMessage(response?.message)
  //         : setToastMessage("Failed to Download audio file");
  //       setToastType("error");
  //       setIsProcessing(false);
  //       return;
  //     }

  //     const arrayBuffer = new Uint8Array(response.audioFile.data).buffer;

  //     // Decode audio data using Web Audio API
  //     const audioContext = new (window.AudioContext ||
  //       window.webkitAudioContext)();
  //     const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

  //     // Assume mono audio
  //     const channelData = audioBuffer.getChannelData(0);

  //     // Convert Float32Array [-1,1] to Int16Array [-32768,32767]
  //     const samples = floatTo16BitPCM(channelData);

  //     // Export the audio
  //     const blob =
  //       fileType === "wav"
  //         ? new Blob([new Uint8Array(response.audioFile.data)], {
  //             type: "audio/wav",
  //           })
  //         : await encodeMP3(samples, audioBuffer.sampleRate);

  //     // Trigger download
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `${currentResponse.audio.name}.${fileType}`;
  //     document.body.appendChild(a);
  //     a.click();
  //     a.remove();
  //     URL.revokeObjectURL(url);

  //     posthog.capture("Audio file Download successful");
  //     setToastMessage("Audio file downloaded successfully");
  //     setToastType("success");
  //   } catch (error) {
  //     posthog.capture("Failed to Download audio file");
  //     console.error("Processing failed:", error);
  //   }
  //   setIsProcessing(false);
  // };

  // Encode to MP3 using lamejs

  const fetchAndProcessAudio = async () => {
    posthog.capture("Click to Download Audio");
    setIsProcessing(true);

    try {
      // console.log("Fetching signed URL...");
      const signedUrlResponse = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/get-signed-url?id=${currentResponse.ai_response_id}`
      );

      if (!signedUrlResponse.ok) {
        const errorMessage = await signedUrlResponse.text();
        console.error("Error fetching signed URL:", errorMessage);
        setToastMessage(errorMessage || "Failed to fetch signed URL");
        setToastType("error");
        setIsProcessing(false);
        return;
      }

      const { url: signedUrl } = await signedUrlResponse.json();
      // console.log("Signed URL:", signedUrl);

      // console.log("Fetching audio file...");
      const audioResponse = await fetch(signedUrl);

      if (!audioResponse.ok) {
        console.error(
          `Error fetching audio file. Status: ${audioResponse.status}`
        );
        throw new Error("Failed to fetch audio file");
      }

      const arrayBuffer = await audioResponse.arrayBuffer();
      // console.log("Audio ArrayBuffer Length:", arrayBuffer.byteLength);

      if (arrayBuffer.byteLength === 0) {
        throw new Error("Downloaded audio file is empty");
      }

      // Process the file based on the selected format
      let blob;
      if (fileType === "wav") {
        // Directly create WAV Blob
        blob = new Blob([arrayBuffer], { type: "audio/wav" });
      } else if (fileType === "mp3") {
        // Decode audio data and convert to MP3
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        // Extract audio samples (mono)
        const channelData = audioBuffer.getChannelData(0);

        // Convert Float32Array [-1, 1] to Int16Array [-32768, 32767]
        const samples = floatTo16BitPCM(channelData);

        // Encode samples to MP3 using lamejs
        blob = await encodeMP3(samples, audioBuffer.sampleRate);
      } else {
        throw new Error("Unsupported file type selected");
      }

      // Trigger download
      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = `${currentResponse.audio.name}.${fileType}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      URL.revokeObjectURL(downloadUrl);

      posthog.capture("Audio file download successful");
      setToastMessage("Audio file downloaded successfully");
      setToastType("success");
    } catch (error) {
      posthog.capture("Failed to Download audio file");
      console.error("Processing failed:", error);
      setToastMessage("Failed to download audio file");
      setToastType("error");
    } finally {
      setIsProcessing(false);
    }
  };

  const encodeMP3 = async (samples, sampleRate) => {
    const mp3encoder = new lamejs.Mp3Encoder(1, sampleRate, 128); // Mono channel
    const sampleBlockSize = 1152;
    const mp3Data = [];

    let sampleIndex = 0;
    while (sampleIndex < samples.length) {
      const sampleChunk = samples.subarray(
        sampleIndex,
        sampleIndex + sampleBlockSize
      );
      const mp3buf = mp3encoder.encodeBuffer(sampleChunk);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
      sampleIndex += sampleBlockSize;
    }

    const mp3buf = mp3encoder.flush();
    if (mp3buf.length > 0) {
      mp3Data.push(mp3buf);
    }

    return new Blob(mp3Data, { type: "audio/mp3" });
  };

  // Helper function to convert Float32Array to Int16Array
  const floatTo16BitPCM = (input) => {
    const output = new Int16Array(input.length);
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
    return output;
  };

  return (
    <div className="fixed inset-0 flex items-center z-20 justify-center bg-[rgba(0,0,0,0.7)]">
      <div className="w-full max-w-[520px] flex flex-col bg-[#1c1b1c] rounded-lg border border-[#4d4a4e] relative">
        <div className="flex items-center justify-between p-3 border-b border-[#2c2a2c]">
          <h2 className="text-lg font-normal text-white">Download Track</h2>
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-[rgba(255,255,255,0.05)]"
          >
            <Xmark className="w-4 h-4 text-[#878188]" />
          </button>
        </div>

        <div className="p-4 flex flex-col gap-4">
          {/* Track Preview */}
          <div className="flex gap-3 p-2 items-center rounded-xl border border-[rgba(255,255,255,0.2)]">
            <img
              src={currentResponse.avatar}
              alt={currentResponse.audio.name}
              className="w-20 h-20 object-cover rounded"
            />
            <div className="flex flex-col flex-1 min-w-0">
              <h3 className="text-base font-normal text-white truncate">
                {currentResponse.audio.name}
              </h3>
              <div className="flex items-center justify-between">
                <span className="text-sm font-extralight text-[#a5a0a6] truncate">
                  {currentResponse.musicDescription}
                </span>
                <div className="flex items-center gap-1 text-[#a5a0a6]">
                  <Clock className="w-3.5 h-3.5" />
                  <span className="text-sm font-extralight">
                    {currentResponse.audio.duration}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex flex-col gap-4">
          {/* File Type */}
          <div className="flex items-center gap-8">
            <span className="w-22 text-sm text-white">File Type</span>
            <div className="bg-[rgba(255,255,255,0.1)] rounded-full p-0.5">
              <button
                onClick={() => setFileType("wav")}
                className={`px-3 h-7 text-xs rounded-full transition-colors ${
                  fileType === "wav"
                    ? "bg-[rgba(255,255,255,0.1)] text-white"
                    : "text-[#878188]"
                }`}
              >
                WAV
              </button>
              <button
                onClick={() => setFileType("mp3")}
                className={`px-3 h-7 text-xs rounded-full transition-colors ${
                  fileType === "mp3"
                    ? "bg-[rgba(255,255,255,0.1)] text-white"
                    : "text-[#878188]"
                }`}
              >
                MP3
              </button>
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end mt-2">
            <button
              onClick={fetchAndProcessAudio}
              disabled={isProcessing}
              className="flex items-center gap-2 px-3 h-9 bg-white rounded-full hover:bg-white/90 transition-colors"
            >
              <span className="text-sm font-medium text-[#0f0e0f]">
                {isProcessing ? "Processing..." : "Download"}
              </span>
            </button>
          </div>
        </div>
      </div>
      {toastMessage && (
        <Toast
          type={toastType}
          message={toastMessage}
          onClose={() => setToastMessage(null)}
        />
      )}
    </div>
  );
};

export default AudioProcessor;
