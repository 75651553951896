import React from "react";
import { usePostHog } from "posthog-js/react";

const NoResultsFound = ({ onClearFilters, buttonText }) => {
  const posthog = usePostHog();

  return (
    <div className="flex flex-col h-1/2 items-center justify-center text-white">
      <h2 className="text-2xl font-semibold mb-2">No results found</h2>
      {!buttonText && (
        <p className="mb-4 text-sm font-extralight">
          Here are some tips while searching or filtering:
        </p>
      )}
      {!buttonText && (
        <ul className="list-disc mb-4 text-sm font-extralight">
          <li>Double-check for typos</li>
          <li>Clear your search filters</li>
          <li>Use fewer or different keywords</li>
        </ul>
      )}
      <button
        onClick={() => {
          posthog.capture(
            `Click to clear ${buttonText ? `search and filters` : `filters`}`
          );
          onClearFilters();
        }}
        className="flex items-center justify-center px-3 py-2 bg-white hover:bg-white/90 font-normal text-[#100f10] text-sm rounded-full"
      >
        {buttonText ? buttonText : <p>Clear search and filters</p>}
      </button>
    </div>
  );
};

export default NoResultsFound;
