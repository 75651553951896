import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Rectangles4, StarFill } from "@gravity-ui/icons";
import { useLocalState } from "../../contexts/LocalStateProvider";
import { getCookie } from "../../utils/cookieUtils";
import { useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

const SidebarProjects = () => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const { localState, updateLocalState } = useLocalState();
  const location = useLocation();
  const isProjectsPage = location.pathname === "/projects";

  useEffect(() => {
    const fetchStarredProjects = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_ENDPOINT}/api/starred-projects`,
          {
            headers: {
              Authorization: `Bearer ${getCookie("token")}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.success) {
          const processedProjects = data.projects.map((project) => ({
            ...project,
            imageSrc: project.signedUrlExists
              ? project.cover_image_name
              : !project.signedUrlExists && project.cover_image_name
              ? `/project-images/${project.cover_image_name}`
              : "/project-images/Album_Art_6.webp",
          }));
          updateLocalState("starredProjects", processedProjects);
        }
      } catch (error) {
        console.error("Error fetching starred projects:", error);
      }
    };

    if (!localState.starredProjects) {
      fetchStarredProjects();
    }
  }, []);

  return (
    <div className="flex flex-col w-full">
      <button
        id="sidebar-link-projects"
        className={` ${
          isProjectsPage ? "text-white" : "text-[#878188]"
        } flex items-center w-full self-stretch h-10 px-3 hover:[background:var(--Color-Opacity-White-5,rgba(255,255,255,0.05))] rounded-lg font-normal text-base text-[#878188] hover:text-white transition duration-200`}
        onClick={() => navigate("/projects")}
      >
        <Rectangles4 className="w-4 h-4 mr-3" />
        Projects
      </button>

      {localState.starredProjects?.length > 0 && (
        <div className="relative ml-8">
          <div className="absolute left-[-12px] top-0 w-[1px] h-full bg-white/15" />
          {localState.starredProjects.map((project) => (
            <button
              key={project.project_id}
              onClick={() => {
                posthog.capture("Go to Starred project navigation");
                navigate(`/chatPage?projectId=${project.project_id}`);
              }}
              className="flex items-center gap-2 w-full h-7 px-2 text-xs text-[#878188] hover:text-white rounded-lg hover:bg-white/5"
            >
              <span className="truncate">{project.project_name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SidebarProjects;
