import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import apiService from "../../api/apiService";
import { useLocalState } from "../../contexts/LocalStateProvider";
import {
  setSubscriptionModal,
  setSubscriptionTypeModal,
  setRedirectAfterSelectedPlan,
} from "../../store/reducers/subscriptionSlice";
import { setUserData } from "../../store/reducers/userSlice";
import Modal from "../modal/Modal";
import CircularProgress from "./CircularProgress";
import Subscription from "./Subscription/Subscription";
import { usePostHog } from "posthog-js/react";
import { getCookie } from "../../utils/cookieUtils";

const CreditsCard = ({ isLongSidebar = true }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { localState, updateLocalState } = useLocalState();
  const { openSubscriptionModal } = useSelector((state) => state.subscription);
  const [openPlanModal, setPlanModal] = useState(false);

  const planMapper = {
    Monthly: {
      Basic: {
        minutes: 30,
      },
      Business: {
        minutes: 120,
      },
      Free: {
        minutes: 10,
      },
    },
    Annual: {
      Basic: {
        minutes: 360,
      },
      Business: {
        minutes: 1440,
      },
      Free: {
        minutes: 10,
      },
    },
  };

  const remainingCredits = localState.userProfileData?.remaining_credits;
  const subscriptionType = localState.userProfileData?.subscription_type;

  // Check if subscriptionType is valid before using it
  const isFreeTier =
    subscriptionType == null || subscriptionType.startsWith("Trial");
  // console.log(subscriptionType);

  const standardPlanMinutes = isFreeTier
    ? 10
    : subscriptionType && subscriptionType?.split(" ")?.length === 2
    ? planMapper[subscriptionType?.split(" ")[1]][
        subscriptionType?.split(" ")[0]
      ].minutes
    : 10;

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const fetchUserData = async () => {
      try {
        const response = await apiService.handleProtectedRoute(
          localState.isTeamMember ? "fetchTeamUserProfile" : "fetchUserProfile",
          {}
        );

        if (isMounted) {
          // Update state only if the component is still mounted
          updateLocalState("userProfileData", response);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, [localState.isTeamMember]); // Empty dependency array ensures this runs only once

  // const handleCreditRequest = async (data) => {
  //   try {
  //     const response = await apiService.handleProtectedRoute(
  //       "createCreditRequest",
  //       {}
  //     );

  //     dispatch(setUserData(response));
  //   } catch (error) {
  //     console.error("Error sending credit request:", error);
  //   }
  // };

  // console.log(localState.userProfileData);

  const requestUpgradeMemberMinutes = async () => {
    const token = getCookie("token"); // Get the token from cookies

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/request-upgrade-member-minutes`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Set the authorization header
          },
          body: JSON.stringify({}), // Send an empty body as in the original
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to request upgrade");
      }

      const data = await response.json();
      return data; // Return the successful response data
    } catch (error) {
      console.error("Error requesting upgrade member minutes:", error);
      throw error; // Rethrow the error for further handling
    }
  };

  // Inside CreditsCard component, add this new useEffect
  useEffect(() => {
    const remainingCredits = localState.userProfileData?.remaining_credits;

    if (remainingCredits <= 5) {
      updateLocalState("lowCreditsWarning", {
        type: "warning",
        message: `Only ${remainingCredits} minutes left! Upgrade now to keep jamming uninterrupted! 🎵✨`,
        action: {
          label:
            localState.isAdmin || !localState.isTeamMember
              ? "Upgrade"
              : "Request Upgrade",
          onClick: async () => {
            updateLocalState("lowCreditsWarning", null);

            if (localState.isAdmin) {
              posthog.capture("Click on Upgrade button");

              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
              dispatch(setSubscriptionTypeModal("upgrade"));
            }
            if (
              localState.userProfileData?.role === "Member" ||
              localState.isTeamMember
            ) {
              posthog.capture("Click on Request Upgrade button");

              requestUpgradeMemberMinutes()
                .then(() => {
                  console.log("Upgrade request sent successfully");
                })
                .catch((error) => {
                  console.error(
                    "Error requesting upgrade member minutes:",
                    error
                  );
                });
            } else if (!localState.isTeamMember) {
              posthog.capture("Click on Upgrade button");

              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
            }
          },
        },
      });
      posthog.capture(`Credits less than 5 minutes for user`, {
        email: localState.userProfileData?.email,
      });
    }
    if (remainingCredits <= 0) {
      updateLocalState("lowCreditsWarning", {
        type: "error",
        message: `Oops! You’ve hit zero minutes. Upgrade to keep the tunes alive! 🎹⏳`,
        action: {
          label:
            localState.isAdmin || !localState.isTeamMember
              ? "Upgrade"
              : "Request Upgrade",
          onClick: async () => {
            updateLocalState("lowCreditsWarning", null);

            if (localState.isAdmin) {
              posthog.capture("Click on Upgrade button");

              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
              dispatch(setSubscriptionTypeModal("upgrade"));
            }
            if (
              (localState.userProfileData?.role === "Member" ||
                localState.isTeamMember) &&
              !localState.isAdmin
            ) {
              posthog.capture("Click on Request Upgrade button");

              requestUpgradeMemberMinutes()
                .then(() => {
                  console.log("Upgrade request sent successfully");
                })
                .catch((error) => {
                  console.error(
                    "Error requesting upgrade member minutes:",
                    error
                  );
                });
            } else if (!localState.isTeamMember) {
              posthog.capture("Click on Upgrade button");

              dispatch(setRedirectAfterSelectedPlan("/create-team"));
              dispatch(setSubscriptionModal(true));
            }
          },
        },
      });
      posthog.capture(`Credits 0 minutes for user`, {
        email: localState.userProfileData?.email,
      });
    } else if (remainingCredits > 5) {
      updateLocalState("lowCreditsWarning", null);
    }
  }, [
    localState.userProfileData?.remaining_credits,
    localState.isTeamMember,
    localState.isAdmin,
  ]);

  return (
    <>
      {isLongSidebar ? (
        <div className="md:w-72 w-full">
          <div className={"w-full"}>
            <div className="flex w-full flex-col md:gap-2 gap-3 rounded-lg bg-[#2C2A2C] md:p-2 p-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="relative h-5 w-5 items-center justify-center">
                    <CircularProgress
                      value={
                        Math.round(
                          localState.userProfileData?.remaining_credits * 10
                        ) / 10 ?? 0
                      }
                      maxValue={localState.userProfileData?.total_credits ?? 0}
                      radius={8}
                    />
                  </div>
                  <span className="md:text-sm text-lg font-extralight text-white">
                    Minutes
                  </span>
                </div>
                {
                  <button
                    variant="outline"
                    size="sm"
                    className="md:h-7 h-9 bg-transparent md:px-2 px-3 md:text-xs text-sm font-normal rounded-full border border-[rgba(255,255,255,0.1)] text-[#878188] hover:bg-white/10 hover:text-white"
                    onClick={() => {
                      posthog.capture("Click on Upgrade button");

                      if (localState.isAdmin) {
                        dispatch(setRedirectAfterSelectedPlan("/create-team"));
                        dispatch(setSubscriptionModal(true));
                        dispatch(setSubscriptionTypeModal("upgrade"));
                      }
                      if (
                        (localState.userProfileData?.role === "Member" ||
                          localState.isTeamMember) &&
                        !localState.isAdmin
                      ) {
                        requestUpgradeMemberMinutes()
                          .then(() => {
                            console.log("Upgrade request sent successfully");
                          })
                          .catch((error) => {
                            console.error(
                              "Error requesting upgrade member minutes:",
                              error
                            );
                          });
                      } else if (!localState.isTeamMember) {
                        dispatch(setRedirectAfterSelectedPlan("/create-team"));
                        dispatch(setSubscriptionModal(true));
                      }
                    }}
                  >
                    {localState.isAdmin || !localState.isTeamMember
                      ? "Upgrade"
                      : "Request mins"}
                  </button>
                }
              </div>
              <div className="flex items-center justify-between md:text-xs text-sm">
                <span className="font-normal text-[#C5C3C6]">Remaining</span>
                <div className="flex items-center gap-1 text-white">
                  <span className="font-normal">
                    {Math.round(
                      localState.userProfileData?.remaining_credits * 10
                    ) / 10 ?? 0}
                  </span>
                  <span className="font-normal">/</span>
                  <span className="font-normal">
                    {standardPlanMinutes ?? 0} mins
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="flex items-center justify-center">
            <div className="relative">
              <CircularProgress
                value={
                  Math.round(
                    localState.userProfileData?.remaining_credits * 10
                  ) / 10 ?? 0
                }
                maxValue={localState.userProfileData?.total_credits ?? 0}
                radius={14}
                shortProgress={true}
              />
              <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-[11px] font-normal">
                {Math.round(
                  (localState.userProfileData?.remaining_credits /
                    localState.userProfileData?.total_credits) *
                    100
                )}
                %
              </span>
            </div>
          </div>
        </div>
      )}
      {openPlanModal && (
        <Modal closeModal={() => setPlanModal(false)}>
          <Subscription
            openPlanModal={openPlanModal}
            setOpenPlanModal={setPlanModal}
          />
        </Modal>
      )}
    </>
    // <div className="flex flex-col rounded-lg  p-4 w-80 bg-[#2C2A2C]">
    //   {/* Top Section */}
    //   <div className="flex justify-between gap-8 items-center">
    //     {/* Credits Section with Icon */}
    //     <div className="flex gap-2 items-center">
    //       <CircularProgress
    //         value={localState.userProfileData?.remaining_credits ?? 0}
    //         maxChars={localState.userProfileData?.total_credits ?? 0}
    //         type="sidebar"
    //       />
    //       <h3 className="text-sm font-semibold text-white">Credits</h3>
    //     </div>

    //     {/* Request Button */}
    //     <button
    //       className="text-white px-3 py-1 text-sm
    //        rounded-md border border-[rgba(255,255,255,0.1)]"
    //       onClick={() => navigate("/subscription")}
    //     >
    //       {localState.isAdmin && localState.isTeamMember ? "Upgrade" : localState.isTeamMember ? "Request" : "Upgrade"}
    //     </button>
    //   </div>

    //   {/* Bottom Section */}
    //   <div className="flex justify-between items-center mt-4">
    //     <span className="text-gray-400 text-sm">Remaining</span>
    //     <span className="text-white font-semibold text-sm">
    //       {userData?.remaining_credits ?? 0} / {userData?.total_credits ?? 0} mins
    //     </span>
    //   </div>
    // </div>
  );
};

export default CreditsCard;
