import React, { useState, useRef, useEffect } from "react";
import {
  PlayFill,
  PauseFill,
  Ellipsis,
  ArrowShapeDownToLine,
  ArrowUturnCwRight,
  ChevronDown,
  ArrowsRotateRight,
  PencilToLine,
} from "@gravity-ui/icons";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import { useLocalState } from "../../contexts/LocalStateProvider"; // Import the local state hook
import apiService from "../../api/apiService";
import Toast from "../generic_components/Toast"; // Import the Toast component
import AudioProcessor from "../custom_components/AudioProcessor";
import { usePostHog } from "posthog-js/react";

const Version = ({ version, index, versionCount }) => {
  const posthog = usePostHog();
  const [isExpanded, setIsExpanded] = useState(index === 0);

  useEffect(() => {
    if (
      version?.hasVariations &&
      version?.versions?.length > 1 &&
      index === 0
    ) {
      setIsExpanded(true);
    }
  }, [version?.versions?.length]);

  // For single versions without variations
  if (!version.hasVariations) {
    return (
      <SingleVersion
        version={version.latest}
        showVersionIcon={false}
        isLastItem={index === versionCount}
      />
    );
  }

  // For groups with variations, show latest version in header
  return (
    <div>
      <div
        onClick={() => {
          // isExpanded && posthog.capture("Expanded version history dropdown");
          setIsExpanded(!isExpanded);
        }}
        className="flex items-center justify-between w-full"
      >
        {/* Header shows only latest version's details */}
        <div className="flex justify-between w-full items-center p-2 rounded-lg cursor-pointer hover:bg-[#1d1b1d] duration-300">
          <div className="flex items-center w-full">
            <img
              src={version.latest.audio.cover}
              alt={version.latest.audio.name}
              className="w-10 h-10 rounded-md"
            />
            <div className="flex flex-col w-full mr-2">
              <span className="ml-2 text-sm font-extralight line-clamp-1 w-full">
                {version.latest.audio.name}
              </span>
              <span className="ml-2 text-xs font-extralight text-[#A5A0A6]">
                {version.versions.length > 1
                  ? `${version.versions.length} versions`
                  : null}
              </span>
            </div>
          </div>
          <ChevronDown
            className={`transform mr-2 ${isExpanded ? "rotate-180" : ""}`}
          />
        </div>
      </div>

      {/* When expanded, show all versions in descending order */}
      {isExpanded && (
        <div className="ml-2">
          {version.versions.map((v, index) => (
            <SingleVersion
              key={index}
              version={v}
              showVersionIcon={true}
              isLastItem={index === version.versions.length - 1}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const SingleVersion = ({ version, showVersionIcon, isLastItem }) => {
  const {
    isPlaying,
    playAudio,
    pauseAudio,
    resumeAudio,
    audioSrc: currentAudioSrc,
    setDiscoverId,
  } = useAudioPlayer();
  const posthog = usePostHog();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const { updateLocalState } = useLocalState(); // Use the local state hook
  const [toastMessage, setToastMessage] = useState(null);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  // Ensure version is defined
  if (!version) {
    return null; // or return a placeholder/fallback UI
  }

  const {
    audio: { src, name, duration, cover } = {},
    musicDescription,
    avatar,
    ai_response_id,
    responseType,
    regenerated,
    parent_response_id,
  } = version;

  // console.log("SingleVersion props:", version);

  const getTimeAgo = (timestamp) => {
    const now = new Date();
    const created = new Date(timestamp);
    const diffMs = now - created;

    const diffInMinutes = Math.floor(diffMs / (1000 * 60));
    const diffInHours = Math.floor(diffMs / (1000 * 60 * 60));
    const diffInDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);

    if (diffInMinutes < 1) return "Just now";
    if (diffInMinutes === 1) return "1 minute ago";
    if (diffInMinutes < 60) return `${diffInMinutes} minutes ago`;
    if (diffInHours === 1) return "1 hour ago";
    if (diffInHours < 24) return `${diffInHours} hours ago`;
    if (diffInDays === 1) return "1 day ago";
    if (diffInDays < 7) return `${diffInDays} days ago`;
    if (diffInWeeks === 1) return "1 week ago";
    if (diffInWeeks < 4) return `${diffInWeeks} weeks ago`;
    if (diffInMonths === 1) return "1 month ago";
    return `${diffInMonths} months ago`;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handlePlayPause = (audioSrc, audioName) => {
    if (isPlaying && currentAudioSrc === audioSrc) {
      pauseAudio();
    } else if (currentAudioSrc === audioSrc) {
      resumeAudio();
    } else {
      setDiscoverId(null);
      playAudio(audioSrc, audioName, musicDescription, avatar, duration);
    }
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
    if (isLastItem && !showDropdown) {
      // Add slight delay to ensure dropdown is rendered
      setTimeout(() => {
        const versionHistoryContainer = document.querySelector(
          ".scrollable-version"
        );
        if (versionHistoryContainer) {
          versionHistoryContainer.scrollTo({
            top: versionHistoryContainer.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 50);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleVersionClick = (e) => {
    e.stopPropagation();
    // Update current song details in local state
    const detailsData = {
      name: version.audio.name,
      description: version.musicDescription,
      coverImage: version.audio.cover,
      duration: version.audio.duration,
      audioSrc: version.audio.src,
      ai_response_id: version.ai_response_id,
      projectId: version.projectId,
      responseType: version.responseType,
      regenerated: version.regenerated,
      created_at: version.created_at,
    };
    updateLocalState("currentSongDetails", detailsData);
  };

  const handleOptionClick = async (option) => {
    if (option === "Download") {
      setIsDownloadModalOpen(true);
    } else if (option === "Go to prompt") {
      posthog.capture("Click on Go to prompt");
      // Update local state with the ai_response_id to trigger scroll
      updateLocalState("scrollToUserInput", parent_response_id);
    }
    setShowDropdown(false);
  };

  return (
    <>
      <div
        id={`version-${ai_response_id}`}
        ref={dropdownRef}
        onClick={(e) => {
          e.stopPropagation();
          handleVersionClick(e);
          handlePlayPause(src, name);
        }}
        className="flex items-center w-full px-2 py-2 rounded-lg cursor-pointer group transition duration-500 hover:bg-[#1d1b1d] relative"
        style={
          currentAudioSrc === src
            ? {
                background:
                  "linear-gradient(0deg, rgba(0, 0, 0, 0.72) 0%, rgba(0, 0, 0, 0.72) 100%), linear-gradient(135deg, #FC10F2 0%, #3078E4 100%)",
              }
            : {}
        }
      >
        <div className="flex gap-2 items-center relative">
          {showVersionIcon && !isLastItem && (
            <div className="absolute left-[0.7rem] top-[2rem] h-full z-10">
              <div className="h-full w-0.5 [background:var(--Color-Opacity-White-10,rgba(255,255,255,0.10))]" />
            </div>
          )}
          {showVersionIcon && (
            <div className="flex items-center px-1 w-6">
              {regenerated ? (
                <ArrowsRotateRight className="w-4 h-4" />
              ) : responseType === "extension" ? (
                <img
                  src="/Change Duration.svg"
                  alt="Extended"
                  className="w-4 h-4"
                />
              ) : responseType === "edit" ? (
                <PencilToLine className="w-4 h-4" />
              ) : (
                <img
                  src="/Ellipse.svg"
                  alt="Original"
                  className="w-3 h-3 ml-0.5"
                />
              )}
            </div>
          )}
          <div className="flex items-center relative overflow-hidden w-10 h-10">
            <img
              src={cover}
              alt="Cover"
              className="w-10 h-10 flex justify-center items-center gap-2 object-cover rounded-md"
            />
            <button
              id="play-pause-button"
              onClick={(e) => {
                e.stopPropagation();
                handlePlayPause(src, name);
              }}
              className={`absolute inset-0 flex items-center justify-center text-white transition-transform duration-500 transform group-hover:opacity-100 ${
                isPlaying && currentAudioSrc === src
                  ? "md:translate-y-0"
                  : "md:translate-y-[80%] md:group-hover:translate-y-0"
              }`}
            >
              {isPlaying && currentAudioSrc === src ? (
                <PauseFill className="w-4 h-4" />
              ) : (
                <PlayFill className="w-4 h-4" />
              )}
            </button>
          </div>
        </div>

        <div className="flex-col items-start mx-2 w-full">
          <div className="font-extralight text-sm text-white line-clamp-1 w-full">
            {name}
          </div>
          <div className="text-xs text-[#878188] w-full">
            {getTimeAgo(version.created_at)}
          </div>
          {/* <div
          className="flex px-1.5 rounded justify-center py-0.5 text-[8px] font-semibold"
          style={{ background: "rgba(255, 255, 255, 0.15)" }}
        >
          {regenerated
            ? "Regenerated"
            : responseType === "extension"
            ? "Extended"
            : "Original"}
        </div> */}
        </div>
        <div className="flex items-center gap-2 ">
          <div className="flex flex-col justify-center items-center grow"></div>
          <span className="font-extralight text-xs text-white">{duration}</span>
        </div>
        <button
          id="dropdown-button"
          className="ml-8 rounded-full text-[#878188] hover:text-white p-1.5 bg-transparent hover:bg-white/5 relative"
          onClick={(e) => {
            e.stopPropagation(); // Prevent triggering play/pause
            handleDropdownToggle();
          }}
        >
          <Ellipsis />
        </button>
        {showDropdown && (
          <div
            id="version-item-dropdown"
            className="absolute p-1 right-2 top-10 text-center font-normal text-sm text-[#878188] bg-[#100f10] rounded-lg border border-solid border-[#2c2a2c] z-10"
          >
            <button
              id="go-to-prompt-button"
              className="flex items-center gap-2 h-8 w-full px-2 hover:text-white rounded-lg text-center hover:bg-zinc-900"
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick("Go to prompt");
              }}
            >
              <ArrowUturnCwRight className="w-4 h-4" /> Go to prompt
            </button>
            <button
              id="download-button"
              className="flex items-center gap-2 h-8 w-full px-2 hover:text-white rounded-lg text-center hover:bg-zinc-900"
              onClick={(e) => {
                e.stopPropagation();
                handleOptionClick("Download");
              }}
            >
              <ArrowShapeDownToLine className="w-4 h-4" /> Download
            </button>
          </div>
        )}
        {toastMessage && (
          <Toast
            type="error"
            message={toastMessage}
            onClose={() => setToastMessage(null)}
          />
        )}
      </div>
      {isDownloadModalOpen && (
        <div className="flex flex-col gap-4 w-full p-6 bg-[#1d1b1d] rounded-lg">
          <AudioProcessor
            currentResponse={{
              ai_response_id: ai_response_id,
              audio: {
                name: name,
                duration:
                  typeof duration === "string"
                    ? duration
                    : formatTime(duration) || "0:00",
              },
              avatar: avatar,
              musicDescription: musicDescription,
            }}
            onClose={() => setIsDownloadModalOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default Version;
