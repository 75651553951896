import React, { useState, useEffect } from "react";
import { PlayFill, PauseFill, Xmark, Check } from "@gravity-ui/icons";
import Modal from "../modal/Modal";
import CustomInput from "../custom_components/CustomInput";
import { useAudioPlayer } from "../../contexts/AudioPlayerContext";
import {
  themeCards,
  moodCollectionCards,
  genreCollectionCards,
  instrumentCollectionCards,
} from "./DiscoverIntro";
import { usePostHog } from "posthog-js/react";

const SmartAssistModal = ({ onClose }) => {
  const posthog = usePostHog();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCards, setSelectedCards] = useState(Array(5).fill(null));
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [steps, setSteps] = useState([]);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [audioDetails, setAudioDetails] = useState({});
  const [playingCardId, setPlayingCardId] = useState(null);
  const [audioDetailsPerStep, setAudioDetailsPerStep] = useState({});
  const [songDetails, setSongDetails] = useState(null);
  const [noMatchError, setNoMatchError] = useState(false);
  const [showSmartAssist, setShowSmartAssist] = useState(true);

  const [audio, setAudio] = useState(null);

  // Update useEffect to check if we already have audio for this step
  useEffect(() => {
    if (steps[currentStep] && !audioDetailsPerStep[currentStep]) {
      const currentStepTitles = steps[currentStep].cards.map(
        (card) => card.title
      );
      fetchAudioDetails(
        currentStepTitles,
        steps[currentStep].title,
        currentStep
      );
    }
  }, [currentStep, steps]);

  useEffect(() => {
    const getRandomCards = (array, count) => {
      const shuffled = [...array].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, count);
    };

    const randomSteps = [
      {
        title: "Theme",
        cards: getRandomCards(themeCards, 5),
      },
      {
        title: "Mood",
        cards: getRandomCards(moodCollectionCards, 5),
      },
      {
        title: "Genre",
        cards: getRandomCards(genreCollectionCards, 5),
      },
      {
        title: "Instrument",
        cards: getRandomCards(instrumentCollectionCards, 5),
      },
    ];

    setSteps(randomSteps);

    // Fetch audio details for all random cards
    const cardTitles = randomSteps.flatMap((step) =>
      step.cards.map((card) => card.title)
    );
    // Only fetch audio for current step's cards
    const currentStepTitles = randomSteps[0].cards.map((card) => card.title);
    fetchAudioDetails(currentStepTitles, randomSteps[0].title);
  }, []);

  // Update fetchAudioDetails to store data with card index
  const fetchAudioDetails = async (cardTitles, stepTitle, stepIndex) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/discover-random-song`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ titles: cardTitles, category: stepTitle }),
        }
      );
      const data = await response.json();
      // console.log(data);

      const audioMapping = {};
      data.forEach((item, index) => {
        if (item) {
          audioMapping[cardTitles[index]] = item.file_name;
        }
      });

      // Store audio details for this specific step
      setAudioDetailsPerStep((prev) => ({
        ...prev,
        [stepIndex]: audioMapping,
      }));
    } catch (error) {
      console.error("Error fetching audio details:", error);
    }
  };

  // Update handleCardClick
  const handleCardClick = (cardId) => {
    const newSelectedCards = [...selectedCards];
    newSelectedCards[currentStep] =
      cardId === newSelectedCards[currentStep] ? null : cardId;
    setSelectedCards(newSelectedCards);
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      handlePauseSong(); // Stop any playing audio
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevious = () => {
    handlePauseSong(); // Stop any playing audio
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const handleFinish = async () => {
    posthog.capture("Click on Surprise me button");
    // Get all selected card titles from each step
    const selectedFilters = steps.map((step, index) => {
      const selectedCard = step.cards.find(
        (card) => card.id === selectedCards[index]
      );
      return {
        category: step.title,
        value: selectedCard?.title,
      };
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_ENDPOINT}/api/get-matching-song`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ filters: selectedFilters }),
        }
      );

      if (response.status === 404) {
        setNoMatchError(true);
        return;
      }

      const songData = await response.json();
      // console.log("Song data:", songData);

      const constructedSongData = {
        audioFile: {
          name: songData.title,
          type: "audio/wav",
          url: `gs://discover_songs/${songData.file_name}`,
        },
        imageSrc: `https://storage.googleapis.com/wubble-discover-files/${songData.image_name}`,
        mood: [
          songData.mood,
          songData.themes,
          songData.instrument,
          songData.genre,
        ]
          .filter(Boolean)
          .join(". "),
      };

      // Now we have the complete song details matching all criteria
      setShowCustomInput(true);
      // Pass the song data to CustomInput
      setSongDetails(constructedSongData);
      setShowSmartAssist(false);
    } catch (error) {
      console.error("Error fetching matching song:", error);
      setNoMatchError(true);
    }
  };

  const handleRedo = () => {
    setShowCustomInput(false);
    setCurrentStep(0);
    setNoMatchError(false);
    setSelectedCards(Array(5).fill(null));
    posthog.capture("Click on Redo button");
  };

  const handlePlaySong = (fileName, cardId) => {
    if (audio) {
      audio.pause();
    }

    const audioSrc = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/stream-discover-audio/${fileName}`;
    const newAudio = new Audio(audioSrc);

    newAudio.play();
    setAudio(newAudio);
    setPlayingCardId(cardId);
  };

  const handlePauseSong = () => {
    if (audio) {
      audio.pause();
      setAudio(null);
      setPlayingCardId(null);
    }
  };

  const isLastStep = currentStep === steps.length - 1;
  const isPreviousDisabled = currentStep === 0;

  return (
    <>
      {!showCustomInput && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/80 z-20 p-4">
          <div className="bg-[#1D1B1D] w-full border border-solid border-[#3c393c] rounded-xl p-3 pb-6 max-w-3xl max-h-[90vh] overflow-y-auto">
            <div className="flex justify-end mb-2">
              <button
                onClick={() => {
                  handlePauseSong();
                  onClose();
                }}
                className="p-2 rounded-full hover:bg-[rgba(255,255,255,0.05)]"
              >
                <Xmark className="w-4 h-4 text-[#878188]" />
              </button>
            </div>
            <>
              <div className="flex justify-center mb-3 gap-2">
                {steps.map((_, index) => (
                  <div
                    key={index}
                    className={`w-3.5 h-3.5 rounded-full ${
                      currentStep === index ? "bg-white" : "bg-[#3C393C]"
                    }`}
                  />
                ))}
              </div>
              <div className="flex flex-col items-center mb-6 gap-0.5">
                <h2 className="flex gap-1 text-2xl font-extralight text-center">
                  Select your favourite
                  <span className="font-bold">
                    {steps[currentStep]?.title?.toLowerCase()}
                  </span>
                </h2>
                <h4 className="text-sm text-center font-extralight text-[#C5C3C6]">
                  You can also listen to the samples below
                </h4>
              </div>
              <div className="w-full grid lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-4 max-[640px]:grid-cols-3 max-[480px]:grid-cols-2 gap-2 mb-6">
                {steps[currentStep]?.cards.map((card) => (
                  <div
                    key={card.id}
                    className={`project-item p-[2px] rounded-2xl ${
                      selectedCards[currentStep] === card.id
                        ? "bg-gradient-to-r from-[#FC10F2] to-[#3078E4]"
                        : ""
                    }`}
                  >
                    <div className="flex flex-col gap-2 w-full p-2 bg-[#2C2A2C] rounded-2xl">
                      <div className="relative h-fit w-full flex flex-col gap-2 grow bg-[#1d1b1d] transform duration-500 hover:bg-[#2c2a2c] rounded-xl group">
                        <div className="relative overflow-hidden rounded-xl">
                          {card.image ? (
                            <img
                              src={card.image}
                              alt={card.title}
                              className="relative w-full h-full object-cover aspect-square"
                            />
                          ) : (
                            card.video && (
                              <video
                                src={card.video}
                                loop
                                muted
                                playsInline
                                className="relative w-full h-full object-cover aspect-square"
                              />
                            )
                          )}

                          <div className="absolute flex w-5 h-5 top-2 left-2 z-10 checkbox-container md:invisible md:group-hover:visible transition-opacity duration-300">
                            <input
                              type="checkbox"
                              checked={selectedCards[currentStep] === card.id}
                              onChange={() => handleCardClick(card.id)}
                              className={`w-5 h-5 rounded-md cursor-pointer appearance-none border ${
                                selectedCards[currentStep] === card.id
                                  ? "bg-gradient-to-r from-[#FC10F2] to-[#3078E4] border-none"
                                  : "border-gray-400 bg-white"
                              }`}
                            />
                            {selectedCards[currentStep] === card.id ? (
                              <button onClick={() => handleCardClick(card.id)}>
                                <Check
                                  className={`absolute top-0.5 left-0.5 text-white  `}
                                />
                              </button>
                            ) : (
                              <button onClick={() => handleCardClick(card.id)}>
                                <Check
                                  className={`absolute top-0.5 left-0.5 text-gray-400  `}
                                />
                              </button>
                            )}
                          </div>

                          <button
                            onClick={(e) => {
                              if (playingCardId === card.id) {
                                handlePauseSong();
                              } else {
                                const fileName =
                                  audioDetailsPerStep[currentStep]?.[
                                    card.title
                                  ];
                                if (fileName) {
                                  handlePlaySong(fileName, card.id);
                                }
                              }
                            }}
                            className={`absolute bottom-3 left-2 p-2 flex justify-center items-center gap-1 bg-white text-[#100f10] rounded-full transform ${
                              playingCardId === card.id
                                ? "md:translate-y-0"
                                : "md:transform md:translate-y-[150%] md:group-hover:translate-y-0"
                            } transition-transform duration-300 ease-in-out`}
                          >
                            {playingCardId === card.id ? (
                              <PauseFill className="w-4 h-4" />
                            ) : (
                              <PlayFill className="w-4 h-4" />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="flex flex-col px-1">
                        <span className="font-normal text-left text-sm break-all text-white w-full">
                          {card.title}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-between px-1 w-full">
                <button
                  className={`px-2.5 h-8 text-sm rounded-full ${
                    isPreviousDisabled
                      ? " [background:var(--Color-Light-Opacity-White-5,rgba(255,255,255,0.05))] text-white/10 cursor-not-allowed"
                      : "text-[#878188] [background:var(--Color-Light-Opacity-White-5,rgba(255,255,255,0.05))] hover:text-white hover:bg-white/10 duration-200"
                  }`}
                  onClick={handlePrevious}
                  disabled={isPreviousDisabled}
                >
                  Previous
                </button>

                {!isLastStep ? (
                  <button
                    className={`px-2.5 h-8 rounded-full ${
                      !selectedCards[currentStep]
                        ? "bg-white text-[#100F10] cursor-not-allowed"
                        : "bg-white text-[#100F10]"
                    }`}
                    onClick={handleNext}
                    disabled={!selectedCards[currentStep]}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className={`flex text-center text-sm items-center gap-1 h-8 px-2.5 rounded-full ${
                      !selectedCards[currentStep]
                        ? "bg-[#4E4A4F] text-[#878188] cursor-not-allowed"
                        : "bg-gradient-to-b from-[#fc10f2] to-[#3078e4] px-2 rounded-full hover:[background:linear-gradient(135deg,#e509e4,#256bb8)] text-white font-normal"
                    }`}
                    onClick={handleFinish}
                    disabled={!selectedCards[currentStep]}
                  >
                    Suprise me
                    <img
                      src="Smart Assist.svg"
                      className={`w-3.5 h-3.5 ${
                        !selectedCards[currentStep] && `opacity-40`
                      }`}
                    />
                  </button>
                )}
              </div>
            </>
            {noMatchError && (
              <div className="text-center space-y-4">
                <p className="text-red-500">
                  No matching song found for your selected combination.
                </p>
                <button
                  onClick={handleRedo}
                  className="px-4 py-2 bg-white text-[#100F10] rounded-full hover:bg-gray-100"
                >
                  Try Different Selections
                </button>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="space-y-6">
        {showCustomInput && (
          <Modal
            closeModal={() => setShowCustomInput(false)}
            showCloseButton={false}
            easyClose={true}
          >
            <CustomInput
              audioFile={songDetails?.audioFile}
              imageSrc={songDetails?.imageSrc}
              mood={songDetails?.mood}
              fromDiscoverPage={true}
              className="w-full md:w-[40rem]"
              requestData={{ createProject: true }}
              chatPage={false}
              onRedo={handleRedo}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default SmartAssistModal;
